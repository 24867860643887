import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View, Box, Loader, Column, Parent, Button } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import ProbuildsEditor from './ProbuildsEditor';
import BlitzAPI from 'common/api/BlitzAPI';
import { initProbuilds, updateProbuilds, updateProbuildsEditor, initTeams } from 'common/reducers/product/lol/actions';
import ProbuildsGrid from './ProbuildsGrid';

function generateStyles(theme) {
  return {};
}
class Probuilds extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { initProbuilds, initTeams } = this.props;
    initTeams();
    initProbuilds();
  }
  setUndoItem = (undoItem) => {
    this.setState({ undoItem });
  }
  undoItem = () => {
    const { undoItem } = this.state;
    const { updateProbuilds } = this.props;
    if (undoItem) {
      const params = { pro_build_player: undoItem }
      BlitzAPI.postProbuilds(params).then(() => {
        updateProbuilds();
        this.setState({ undoItem: null });
      })
    }
  }
  render() {
    const {
      gstyles,
      theme,
      styles,
      loaderProbuilds,
      probuildsEditor,
      updateProbuildsEditor,
      loaderTeams
    } = this.props;

    const { undoItem } = this.state;
    return (
      <Loader loader={loaderProbuilds}>
        {loaderProbuilds.isReceived && loaderTeams.isReceived && (
          <Parent>
            <Column columns={8}>
              <Box>
                <ProbuildsGrid setUndoItem={this.setUndoItem} />
              </Box>
            </Column>
            <Column columns={4}>
              <View style={{ position: 'fixed' }}>
                {probuildsEditor && (
                  <Button
                    label="Toggle Create Editor"
                    onClick={() => updateProbuildsEditor(null)}
                    style={[gstyles.bottom_4, { background: theme.blue() }]}
                  />
                )}

                <Box>
                  {probuildsEditor ? (
                    <ProbuildsEditor initialValues={probuildsEditor}/>
                  ) : (
                    <ProbuildsEditor initialValues={{ accounts_name: [{ name: '', region: 'na1' }], region: 'na1' }} isCreator />
                  )}
                </Box>

                {undoItem && (
                  <Button
                    label="Undo Delete"
                    onClick={() => this.undoItem()}
                    style={[gstyles.top_4, { background: theme.red() }]}
                  />
                )}
              </View>
            </Column>
          </Parent>
        )}
      </Loader>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    loaderProbuilds: state.loading.init_probuilds,
    probuildsEditor: state.product.lol.probuildsEditor,
    loaderTeams: state.loading.init_teams,
  };
}

export default connect(mapStateToProps, { initProbuilds, updateProbuildsEditor, updateProbuilds, initTeams })(
  Radium(Probuilds),
);
