import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import POI from './POI';

class MapDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: '',
      name: '',
      images: {
        hero: '',
        layout: '',
        side_orientations: {
          attacking: '',
          defending: '',
          neutral: '',
        },
        walls: {
          attacking: '',
          defending: '',
          neutral: '',
        },
        labels: {
          attacking: '',
          defending: '',
          neutral: '',
        },
      },
      pois: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddPoi = this.handleAddPoi.bind(this);
    this.handlePoiChange = this.handlePoiChange.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state)
      this.setState({
        name: this.props.location.state.name,
        images: this.props.location.state.images,
        pois: this.props.location.state.pois,
        _id: this.props.location.state._id,
        key: this.props.location.state.key
      });
  }

  handleChange(event) {
    const target = event.target;
    const images = this.state.images;
    switch (target.name) {
      case 'name':
        this.setState({ name: target.value });
        break;
      case 'key':
        this.setState({ key: target.value });
        break;
      case 'hero':
        images.hero = target.value;
        this.setState({ images });
        break;
      case 'layout':
        images.layout = target.value;
        this.setState({ images });
        break;
      case 'sideOrientationAttacking':
        images.side_orientations.attacking = target.value;
        this.setState({ images });
        break;
      case 'sideOrientationDefending':
        images.side_orientations.defending = target.value;
        this.setState({ images });
        break;
      case 'sideOrientationNeutral':
        images.side_orientations.neutral = target.value;
        this.setState({ images });
        break;
      case 'wallsAttacking':
        images.walls.attacking = target.value;
        this.setState({ images });
        break;
      case 'wallsDefending':
        images.walls.defending = target.value;
        this.setState({ images });
        break;
      case 'wallsNeutral':
        images.walls.neutral = target.value;
        this.setState({ images });
        break;
      case 'labelsAttacking':
        images.labels.attacking = target.value;
        this.setState({ images });
        break;
      case 'labelsDefending':
        images.labels.defending = target.value;
        this.setState({ images });
        break;
      case 'labelsNeutral':
        images.labels.neutral = target.value;
        this.setState({ images });
        break;
      default:
        break;
    }
  }

  handlePoiChange(newPois, poiId) {
    const pois = this.state.pois.map((poi, index) =>
      index === poiId ? newPois : this.state.pois[index]
    );
    this.setState({ pois });
  }

  handleAddPoi(event) {
    event.preventDefault();
    const newPoi = { name: '', top: '', left: '', video: '' };
    const pois = this.state.pois.concat(newPoi);
    this.setState({ pois });
  }

  render() {
    const { key, name, images } = this.state;
    return (
      <form>
        <div className="form-group">
          <h3 className="font-weight-bold">Basic Info</h3>
          <div className="row">
            <div className="col">
              <label className="font-weight-bold">Name</label>
              <input
                className="form-control"
                name="name"
                type="text"
                value={name}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Key</label>
              <input
                className="form-control"
                name="key"
                type="text"
                value={key}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Hero Image</label>
              <input
                className="form-control"
                name="hero"
                type="text"
                value={images.hero}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="form-group">
          <h3 className="font-weight-bold">Map Layout</h3>
          {images.layout && <img src={images.layout} width="100" />}
          <input
            className="form-control"
            name="layout"
            type="text"
            value={images.layout}
            onChange={this.handleChange}
          />
        </div>
        <br />
        {images.layout && (
          <div className="form-group">
            <h5 className="font-weight-bold">Side Orientation</h5>
            <div className="row">
              <div className="col">
                <label className="font-weight-bold">Attacking (degrees)</label>
                <input
                  className="form-control"
                  placeholder="Attacking"
                  name="sideOrientationAttacking"
                  type="text"
                  value={images.side_orientations.attacking}
                  onChange={this.handleChange}
                />
                {Number.isInteger(images.side_orientations.attacking) && (
                  <img
                    src={images.layout}
                    style={{
                      transform: `rotate(${images.side_orientations.attacking}deg)`,
                    }}
                    width="60"
                  />
                )}
              </div>
              <div className="col">
                <label className="font-weight-bold">Defending (degrees)</label>
                <input
                  className="form-control"
                  placeholder="Defending"
                  name="sideOrientationDefending"
                  type="text"
                  value={images.side_orientations.defending}
                  onChange={this.handleChange}
                />
                {Number.isInteger(images.side_orientations.defending) && (
                  <img
                    src={images.layout}
                    style={{
                      transform: `rotate(${images.side_orientations.defending}deg)`,
                    }}
                    width="60"
                  />
                )}
              </div>
              <div className="col">
                <label className="font-weight-bold">Neutral (degrees)</label>
                <input
                  className="form-control"
                  placeholder="Neutral"
                  name="sideOrientationNeutral"
                  type="text"
                  value={images.side_orientations.neutral}
                  onChange={this.handleChange}
                />
                {Number.isInteger(images.side_orientations.neutral) && (
                  <img
                    src={images.layout}
                    style={{
                      transform: `rotate(${images.side_orientations.neutral}deg)`,
                    }}
                    width="60"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <br />
        <hr />
        <br />
        <div className="form-group">
          <h3 className="font-weight-bold">Map Spawn Walls Images</h3>
          <div className="row">
            <div className="col">
              <label className="font-weight-bold">Attacking walls</label>
              <input
                className="form-control"
                placeholder="Attacking"
                name="wallsAttacking"
                type="text"
                value={images.walls.attacking}
                onChange={this.handleChange}
              />
              {images.walls.attacking && (
                <img src={images.walls.attacking} width="60" />
              )}
            </div>
            <div className="col">
              <label className="font-weight-bold">Defending Walls</label>
              <input
                className="form-control"
                placeholder="Defending"
                name="wallsDefending"
                type="text"
                value={images.walls.defending}
                onChange={this.handleChange}
              />
              {images.walls.defending && (
                <img src={images.walls.defending} width="60" />
              )}
            </div>
            <div className="col">
              <label className="font-weight-bold">Neutral Walls</label>
              <input
                className="form-control"
                placeholder="Neutral"
                name="wallsNeutral"
                type="text"
                value={images.walls.neutral}
                onChange={this.handleChange}
              />
              {images.walls.neutral && (
                <img src={images.walls.neutral} width="60" />
              )}
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="form-group">
          <h3 className="font-weight-bold">Map Labels (Callouts) Images</h3>
          <div className="row">
            <div className="col">
              <label className="font-weight-bold">Attacking Labels</label>
              <input
                className="form-control"
                placeholder="Attacking"
                name="labelsAttacking"
                type="text"
                value={images.labels.attacking}
                onChange={this.handleChange}
              />
              {images.labels.attacking && (
                <img src={images.labels.attacking} width="60" />
              )}
            </div>
            <div className="col">
              <label className="font-weight-bold">Defending Labels</label>
              <input
                className="form-control"
                placeholder="Defending"
                name="labelsDefending"
                type="text"
                value={images.labels.defending}
                onChange={this.handleChange}
              />
              {images.labels.defending && (
                <img src={images.labels.defending} width="60" />
              )}
            </div>
            <div className="col">
              <label className="font-weight-bold">Neutral Labels</label>
              <input
                className="form-control"
                placeholder="Neutral"
                name="labelsNeutral"
                type="text"
                value={images.labels.neutral}
                onChange={this.handleChange}
              />
              {images.labels.neutral && (
                <img src={images.labels.neutral} width="60" />
              )}
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="form-group">
          <Link
            to={{
              pathname: '/valorant/maps',
              state: {
                mapItem: this.state,
                id: this.props.location.state && this.props.location.state.id,
              },
            }}
          >
            <button className="btn btn-primary btn-lg btn-block">Submit</button>
          </Link>
        </div>
      </form>
    );
  }
}

export default MapDetails;
