import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import BlitzAPI from 'common/api/BlitzAPI';
import LeagueStatic from 'view/global/utils/LeagueStatic';

function generateStyles(theme) {
  return {};
}
class AccountField extends Component {
  state = {};
  componentDidMount() {
    this.initAccount(this.props.input.value);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ account: null });
      this.initAccount(this.props.input.value);
    }
  }
  initAccount = value => {
    const splitMember = value.split('_');
    const region = splitMember[0];
    const accountId = value.substr(region.length + 1);
    BlitzAPI.getSummonerById(region, accountId).then(response => {
      BlitzAPI.getLeagues(region, response.data.data.id).then(response => {
        console.log(response.data.data);
        const leagues = response.data.data;
        const league = leagues.find(league => league.queueType === 'RANKED_SOLO_5x5');
        this.setState({ league });
      });
      this.setState({ account: response.data.data });
    });
  };
  render() {
    const { gstyles, theme, styles, label, style } = this.props;
    const { input: { value } } = this.props;
    const { account, league } = this.state;
    console.log(league);
    return (
      <View row>
        {league ? (
          <View row style={{ alignItems: 'center' }}>
            <img
              style={[{ width: 24, height: 24, marginTop: -2 }]}
              src={LeagueStatic.getMedalIcons(league)}
              alt=""
            />
            <div style={[gstyles.caption, gstyles.right_4, { color: theme.text(0.5) }]}>
              {league.tier[0]}
              {league.rank}
            </div>
          </View>
        ) : (
          <img
            style={[{ width: 24, height: 24, marginTop: -2 }]}
            src={LeagueStatic.getMedalIcons(league)}
            alt=""
          />
        )}
        <View style={style}>
          {!account && <div style={[gstyles.caption, { color: theme.text() }]}>loading...</div>}
          {account && <div style={[gstyles.caption, { color: theme.text() }]}>{account.name}</div>}
          {account && <div style={[gstyles.footnote, { color: theme.text(0.5) }]}>{account.id}</div>}
        </View>
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(Radium(AccountField));
