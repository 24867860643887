import React, {Component} from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import { updateTeams, updateTeamsEditor } from 'common/reducers/product/lol/actions';
import BlitzAPI from 'common/api/BlitzAPI';


class TeamGridItem extends Component {
  onDelete = item => {
    const { updateTeams, setUndoItem } = this.props;
    BlitzAPI.deleteTeams(item.id).then(() => {
      updateTeams();
      setUndoItem(item);
    });
  };
  render() {
    const { item, gstyles, theme, updateTeamsEditor } = this.props;
    return (
        <View style={{ width: 100, height: 150, cursor: 'pointer' }}>
          <View  onClick={() => updateTeamsEditor(item)}>
          <div style={[gstyles.p1, { color: theme.text() }, gstyles.bottom_4]}>{item.name}</div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>{item.picture_url}</div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>{item.region}</div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>{item.tag}</div>
          </View>
          <div
              className="hvr-color-red"
              onClick={() => this.onDelete(item)}
              style={[gstyles.p1, {color: theme.text(0.5)}, gstyles.bottom_4, gstyles.top_4]}>delete
          </div>
        </View>);
  }
}
function generateStyles(theme) {
  return {};
}
const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps, { updateTeams, updateTeamsEditor })(
    Radium(TeamGridItem),
);


