import React, { Component } from 'react';

class Ability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: '',
      name: '',
      type: '',
      chargesCount: '',
      description: '',
      video: '',
      videoWebm: '',
      cost: '',
      keybind: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.ability);
  }

  handleChange(event) {
    const target = event.target;
    switch (target.name) {
      case 'abilityName':
        this.setState(
          { key: target.value.toLowerCase(), name: target.value },
          () => this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'abilityType':
        this.setState({ type: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'chargesCount':
        this.setState({ chargesCount: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'description':
        this.setState({ description: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'video':
        this.setState({ video: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'video-webm':
        this.setState({ videoWebm: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'cost':
        this.setState({ cost: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
      case 'keybind':
        this.setState({ keybind: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;
     case 'key':
        this.setState({ key: target.value }, () =>
          this.props.handleAbilityChange(this.state, this.props.abilityId)
        );
        break;   
    }
    this.props.handleAbilityChange(this.state, this.props.abilityId);
  }

  render() {
    const {
      name,
      type,
      chargesCount,
      description,
      video,
      videoWebm,
      cost,
      keybind,
      key
    } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Ability Name</label>
            <input
              className="form-control"
              placeholder="Name"
              name="abilityName"
              type="text"
              value={name}
              onChange={this.handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Ability Type</label>
            <input
              className="form-control"
              placeholder="Type"
              name="abilityType"
              type="text"
              value={type}
              onChange={this.handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Ability Charges</label>
            <input
              className="form-control"
              placeholder="Charges Count"
              name="chargesCount"
              type="text"
              value={chargesCount}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Ability Cost</label>
            <input
              className="form-control"
              placeholder="Cost"
              name="cost"
              type="text"
              value={cost}
              onChange={this.handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Ability Key Bind</label>
            <input
              className="form-control"
              placeholder="Keybind"
              name="keybind"
              type="text"
              value={keybind}
              onChange={this.handleChange}
            />
          </div>
           <div className="col">
            <label className="font-weight-bold">Ability Key</label>
            <input
              className="form-control"
              placeholder="Key"
              name="key"
              type="text"
              value={key}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Ability Description</label>
            <textarea
              className="form-control"
              placeholder="Description"
              name="description"
              value={description}
              onChange={this.handleChange}
              rows="2"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Ability Video (mp4)</label>
            <input
              className="form-control"
              placeholder="MP4 Ability Video"
              name="video"
              type="text"
              value={video}
              onChange={this.handleChange}
            />
            <video
              src={`https://blitz-cdn-videos.blitz.gg/valorant/agents/${video}`}
              width="200"
              controls
              style={{ marginTop: '1rem' }}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Ability Video (webm)</label>
            <input
              className="form-control"
              placeholder="WebM Ability Video"
              name="video-webm"
              type="text"
              value={videoWebm}
              onChange={this.handleChange}
            />
            {videoWebm && (
              <video
                src={`https://blitz-cdn-videos.blitz.gg/valorant/agents/${videoWebm}`}
                width="200"
                controls
                style={{ marginTop: '1rem' }}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Ability;
