import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'react-router-redux';
import configureStore from 'common/store/configureStore';
import Platform from './Platform';
import {
  initDDragon,
  initCurrentPatch,
  initPatch,
} from 'common/reducers/product/lol/actions';
import { load } from 'common/reducers/loading/actions';
import LeagueStatic from 'view/global/utils/LeagueStatic';
const { store, persistor, history } = configureStore();

const onBeforeLift = () => {
  const { dispatch, getState } = store;
  switch (getState().settings.theme.id) {
    case 0:
      require('view/assets/css/light_theme.css');
      break;
    case 1:
      require('view/assets/css/dark_theme.css');
      break;
    default:
      break;
  }
  const init = dispatch(initPatch()).then((patch) => {
    LeagueStatic.setPatch(patch.version);
    dispatch(initCurrentPatch());
    return dispatch(initDDragon(patch.version));
  });
  dispatch(load('init', init));
};
export default class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          <ConnectedRouter history={history}>
            <Platform />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}
