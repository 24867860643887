import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import BlitzAPI from 'common/api/BlitzAPI';
import ProbuildsForm from './ProbuildsForm';
import { updateProbuilds, updateProbuildsEditor } from 'common/reducers/product/lol/actions';
import { SubmissionError } from 'redux-form';
import moment from 'moment';

function generateStyles(theme) {
  return {};
}
class ProbuildsEditor extends Component {
  constructor(props) {
    super(props);

  }
  static getDerivedStateFromProps(props, state) {
    if (state.initialValues !== props.initialValues) {
      return {
        initialValues: props.initialValues,
        updatedAt: null,
      }
    }
    return null;
  }
  onSubmit = (item) => {
    const { updateProbuilds, isCreator, updateProbuildsEditor } = this.props;
    const params = { pro_build_player: item }
    const submit = isCreator ? BlitzAPI.postProbuilds(params) : BlitzAPI.patchProbuilds(item.id, params);
    return submit.then(r => {
      this.setState({updatedAt: moment()})
      return updateProbuilds()
    });
  }
  onProbuildsSubmit = item => {
    console.log(item);
    const { isCreator } = this.props;
    if (!item.accounts_name || item.accounts_name.length === 0) {
      return this.onSubmit(item)
    }
    const getSummoners = item.accounts_name.map((account, i) => {
      return BlitzAPI.getSummonerBySummonerId(account.region, account.name).catch(e => {
        throw { index: i, account: account, error: e };
      });
    });
    return Promise.all(getSummoners)
      .then(responses => {

        const accounts = responses.map(r => {
          return r.data.data;
        })
        const ids = item.accounts_name.map((account, i) => {
          const id = accounts[i].accountId;
          return `${account.region}_${id}`;
        })
        const newItem = Object.assign({}, item, {
          accounts: item.accounts ? [...item.accounts, ...ids] : ids,
          encrypted_accounts: item.accounts ? [...item.accounts, ...ids] : ids,
        });
        delete newItem.accounts_name
        console.log(newItem)
        return this.onSubmit(newItem)
      })
      .catch(error => {
        console.log(error)
        throw new SubmissionError({
          name: 'User does not exist. Check name and region.',
          _error: 'Submission Failed',
        });
      });
  };
  render() {
    const { gstyles, theme, styles, initialValues, teams } = this.props;
    const { updatedAt } = this.state;
    return (
        <ProbuildsForm
          onSubmit={this.onProbuildsSubmit}
          initialValues={initialValues}
          updatedAt={updatedAt}
          teams={teams}
        />
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    teams: state.product.lol.teams
  };
}

export default connect(mapStateToProps, { updateProbuilds, updateProbuildsEditor })(Radium(ProbuildsEditor));
