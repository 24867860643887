import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { fetchAndUpdateAgents, deleteAgent } from 'common/reducers/product/valorant/actions';
import { connect } from 'react-redux';

class ValorantAgents extends Component {

  componentDidMount() {
    this.props.dispatch(fetchAndUpdateAgents());
  }

  handleDeleteAgent(target) {
    const otherAgents = this.props.ListOfAgents.filter(
      agent => agent.key !== target.key
    );
    this.props.dispatch(deleteAgent({ agent: target , otherAgents }));
  }

  render() {
    const { ListOfAgents } = this.props;
    return (
      <div>
        <div className="add-content-list-item-button">
          <Link
            to={{
              pathname: '/valorant/agents/new-agent',
            }}
          >
            <button className="btn btn-larg btn-primary btn-block">
              Add New Agent
            </button>
          </Link>
        </div>
        <table className="table table-borderless">
          <tbody>
            {ListOfAgents.map((agent, index) => (
              <tr key={index}>
                <td className="table-cell">
                  <img src={agent.images.cutout} width="100px" />
                </td>
                <td className="table-cell">{agent.name}</td>
                <td className="table-cell">{agent.Class}</td>

                <td className="table-cell">
                  <Link
                    to={{
                      pathname: `/valorant/agents/${agent.key}`,
                    }}
                  >
                    <i
                      className="fa fa-pencil edit-icon"
                      aria-hidden="true"
                    ></i>
                  </Link>
                </td>
                <td className="table-cell">
                  <i
                    onClick={() => this.handleDeleteAgent(agent)}
                    className="fa fa-trash delete-icon"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot />
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ListOfAgents: state.product.valorant.agents
})

export default connect(mapStateToProps)(ValorantAgents);
