export const REGIONS = {
  NA: 'na1',
  EUNE: 'eun1',
  EUW: 'euw1',
  JP: 'jp1',
  KR: 'kr',
  LAN: 'la1',
  LAS: 'la2',
  BR: 'br1',
  OCE: 'oc1',
  TR: 'tr1',
  RU: 'ru',
  SG: 'sg',
  ID: 'id1',
  VN: 'vn',
  TH: 'th',
  TW: 'tw',
};
