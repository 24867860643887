import React, {Component} from 'react';
import {connect} from 'react-redux';
import Radium from 'radium';
import TeamsForm from './TeamsForm'
import {View, Box, Loader, Column, Parent, Button} from 'view/global/components';
import {generateStylesSelector} from 'view/global/utils/selectors';
import BlitzAPI from 'common/api/BlitzAPI';
import { updateTeams, updateTeamsEditor } from 'common/reducers/product/lol/actions';
import moment from 'moment';



class TeamsEditor extends Component {
  constructor(props) {
    super(props);

  }
  onSubmit = (item) => {
    const { isCreator, updateTeams } = this.props;
    const da = { data: item }
    const submit = isCreator ? BlitzAPI.postTeams(item) : BlitzAPI.patchTeams(item.id, item);
    return submit.then(r => {
      this.setState({updatedAt: moment()})
      return updateTeams()
    });
  }
  onTeamSubmit = item => {
    return this.onSubmit(item);
  };
  static getDerivedStateFromProps(props, state) {
    if (state.initialValues !== props.initialValues) {
      return {
        initialValues: props.initialValues,
        updatedAt: null,
      }
    }
    return null;
  }

  render() {
    const { initialValues } = this.props;
    const { updatedAt } = this.state;
    return (
        <TeamsForm 
        onSubmit={this.onTeamSubmit} 
        initialValues={initialValues}
        updatedAt={updatedAt}
        />
    );
  }
}

function generateStyles(theme) {
  return {};
}

const stylesSelector = generateStylesSelector(generateStyles);

function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps, { updateTeams, updateTeamsEditor })(Radium(TeamsEditor));
