import React, {Component} from 'react';
import {connect} from 'react-redux';
import Radium from 'radium';
import {View, Box, Loader, Column, Parent, Button} from 'view/global/components';
import TeamGrid from './TeamsGrid'
import TeamsEditor from "./TeamsEditor";
import { initTeams, updateTeams, updateTeamsEditor } from 'common/reducers/product/lol/actions';
import { generateStylesSelector } from 'view/global/utils/selectors';
import BlitzAPI from 'common/api/BlitzAPI';


class Teams extends Component {
  constructor(props) {
    super(props);
  }
  setUndoItem = (undoItem) => {
    this.setState({ undoItem });
  }
  undoItem = () => {
    const { undoItem } = this.state;
    const { updateTeams } = this.props;
    if (undoItem) {
      BlitzAPI.postTeams(undoItem).then(() => {
        updateTeams();
        this.setState({ undoItem: null });
      })
    }
  };
  componentDidMount() {
    const { initTeams } = this.props;
     initTeams();
  }

  render() {
    const {gstyles, theme, teamsEditor, loaderTeams, updateTeamsEditor } = this.props;
    const { undoItem } = this.state;
    return (<Loader loader={loaderTeams}>
    {loaderTeams.isReceived && (<Parent>
      <Column columns={8}>
        <Box>
          <TeamGrid setUndoItem={this.setUndoItem} />
        </Box>
      </Column>
      <Column columns={4}>
        <View style={{position: 'fixed'}}>
          {teamsEditor && (<Button
              label="Toggle Create Editor"
              onClick={() => updateTeamsEditor(null)}
              style={[gstyles.bottom_4, {background: theme.blue()}]}
          />)}
          <Box>
            {teamsEditor ? (
              <TeamsEditor initialValues={teamsEditor}/>
            ) : (
              <TeamsEditor initialValues={{region: 'na1'}} isCreator/>
            )}
          </Box>
          {undoItem && (
              <Button
                  label="Undo Delete"
                  onClick={() => this.undoItem()}
                  style={[gstyles.top_4, { background: theme.red() }]}
              />
          )}
        </View>
      </Column>
    </Parent>)}
    </Loader>)
  }
}
function generateStyles(theme) {
  return {};
}
const stylesSelector = generateStylesSelector(generateStyles);

function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    teamsEditor: state.product.lol.teamsEditor,
    loaderTeams: state.loading.init_teams,
  };
}

export default connect(mapStateToProps, {  initTeams, updateTeams, updateTeamsEditor })(
    Radium(Teams),
);