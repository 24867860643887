import * as ActionTypes from './actionTypes';

const FEATURES = [
  'init',
  'init_metrics',
  'init_champion_insights',
  'init_single_insight',
  'init_probuilds',
  'init_teams',
  'init_tiers',
  'init_meta_report',
  'init_single_meta_report',
  'init_fn_toast',
  'init_lol_toast',
];
const defaultState = FEATURES.reduce((previous, current) => Object.assign({}, previous, {
  [current]: {
    isRequesting: false,
    isReceived: false,
    error: null,
    lastUpdated: null,
  },
}), {});

function loading(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_DATA:
      return Object.assign({}, state, {
        [action.feature]: Object.assign({}, state[action.feature], {
          isRequesting: true,
          isReceived: false,
          error: null,
        }),
      });
    case ActionTypes.RECEIVED_DATA:
      return Object.assign({}, state, {
        [action.feature]: Object.assign({}, state[action.feature], {
          isRequesting: false,
          isReceived: true,
          lastUpdated: new Date().toISOString(),
        }),
      });
    case ActionTypes.CATCH_ERROR:
      return Object.assign({}, state, {
        [action.feature]: Object.assign({}, state[action.feature], {
          isRequesting: false,
          error: action.error,
        }),
      });
    case ActionTypes.RESET:
      return Object.assign({}, state, {
        [action.feature]: Object.assign({}, state[action.feature], {
          isRequesting: false,
          isReceived: false,
          error: null,
          lastUpdated: null,
        }),
      });
    default:
      return state;
  }
}

export default loading;
