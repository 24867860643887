import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';

function generateStyles(theme) {
  return {};
}
class Add extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, styles, onClick, style } = this.props;
    const transformedStyle = Array.isArray(style) ? Object.assign({}, ...style) : style;
    return (
      <View
        onClick={onClick}
        className={"hvr-bg-red"}
        style={[
          {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.red(0.5),
            borderRadius: 32,
            height: 32,
            width: 32,
            cursor: 'pointer',
          },
          transformedStyle,
        ]}>
        <div style={[gstyles.h4_bold, { lineHeight: null, color: theme.light() }]}>
          +
        </div>
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(Radium(Add));
