export const LIGHT = (opacity = 1.0) => `rgba(255, 255, 255, ${opacity})`;
export const DARK = (opacity = 1.0) => `rgba(1, 17, 24, ${opacity})`;
export const RED = (opacity = 1.0) => `rgba(243, 83, 83, ${opacity})`;
export const BLUE = (opacity = 1.0) => `rgba(51, 163, 243, ${opacity})`;
export const YELLOW = (opacity = 1.0) => `rgba(255, 155, 60, ${opacity})`;
export const GREEN = (opacity = 1.0) => `rgba(38, 194, 129, ${opacity})`;
export const ORANGE = (opacity = 1.0) => `rgba(255, 155, 60, ${opacity})`;
export const ONYX_SLATE = (opacity = 1.0) => `rgba(27, 40, 56, ${opacity})`;
export const ONYX_EXTRA_SLATE = (opacity = 1.0) => `rgba(24, 35, 50, ${opacity})`;

export const ROKA_SLATE = (opacity = 1.0) => `rgba(247, 247, 248, ${opacity})`
export const WHITE = (opacity = 1.0) => `rgba(255, 255, 255, ${opacity})`
