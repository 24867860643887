import React, { Component } from 'react';

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      start: '',
      end: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.video);
  }

  handleChange(event) {
    const target = event.target;
    switch (target.name) {
      case 'videoSource':
        this.setState({ id: target.value }, () =>
          this.props.handleVideoChange(this.state, this.props.videoId)
        );
        break;
      case 'start':
        this.setState({ start: target.value }, () =>
          this.props.handleVideoChange(this.state, this.props.videoId)
        );
        break;
      case 'end':
        this.setState({ end: target.value }, () =>
          this.props.handleVideoChange(this.state, this.props.videoId)
        );
        break;
    }
  }

  render() {
    const { id, start, end } = this.state;
    return (
      <div className="row">
        <div className="col">
          <label className="font-weight-bold">YT Id</label>
          <input
            className="form-control"
            placeholder="Video ID"
            name="videoSource"
            type="text"
            value={id}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">Start time (optional)</label>
          <input
            className="form-control"
            placeholder="Start"
            name="start"
            type="text"
            value={start}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">End time (optional)</label>
          <input
            className="form-control"
            placeholder="End"
            name="end"
            type="text"
            value={end}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default Video;
