import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import ProbuildsGridItem from './ProbuildsGridItem';

function generateStyles(theme) {
  return {};
}
function sortProbuilds(props, key, opposite) {
  const { probuilds } = props;
  return probuilds.sort((a, b) => {
    const nameA = a[key]; // ignore upper and lowercase
    const nameB = b[key]; // ignore upper and lowercase
    if (nameA < nameB) {
      return opposite ? 1 : -1;
    }
    if (nameA > nameB) {
      return opposite ? -1 : 1;
    }
    // names must be equal
    return 0;
  });
}

class ProbuildsGrid extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = prevState;
    if (prevState.probuilds !== nextProps.probuilds) {
      state.sortedProbuilds = sortProbuilds(nextProps, 'name');
      state.probuilds = nextProps.probuilds;
      state.sortedKey = 'name';
    }
    return state;
  }
  sort = (key, opposite) => {
    const sortedProbuilds = sortProbuilds(this.state, key, opposite);
    this.setState({ sortedProbuilds, sortedKey: key });
  };
  render() {
    const { gstyles, theme, styles, setUndoItem, teams } = this.props;
    const { sortedProbuilds, sortedKey } = this.state;
    return (
      <View>
        <View row style={gstyles.bottom_1}>
          <div style={[gstyles.caption, { color: theme.text() }]}>
            {sortedProbuilds.length} Pro Players
          </div>
          <div style={{ flex: 1 }} />
          <div
            onClick={() => this.sort('name')}
            style={[
              gstyles.caption,
              {
                color: sortedKey === 'name' ? theme.text() : theme.text(0.5),
                cursor: 'pointer',
                fontWeight: sortedKey === 'name' && 'bold',
              },
              gstyles.right_2,
            ]}>
            Name
          </div>
          <div
            onClick={() => this.sort('region')}
            style={[
              gstyles.caption,
              {
                color: sortedKey === 'region' ? theme.text() : theme.text(0.5),
                cursor: 'pointer',
                fontWeight: sortedKey === 'region' && 'bold',
              },
              gstyles.right_2,
            ]}>
            Region
          </div>
          <div
            onClick={() => this.sort('fame', true)}
            style={[
              gstyles.caption,
              {
                color: sortedKey === 'fame' ? theme.text() : theme.text(0.5),
                cursor: 'pointer',
                fontWeight: sortedKey === 'fame' && 'bold',
              },
            ]}>
            Fame
          </div>
        </View>
        <View row style={{ flexWrap: 'wrap' }}>
          {sortedProbuilds.map(data => {
            return <ProbuildsGridItem setUndoItem={setUndoItem} key={data.id} item={data} teams={teams} />;
          })}
        </View>
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    probuilds: state.product.lol.probuilds,
    teams: state.product.lol.teams
  };
}

export default connect(mapStateToProps)(Radium(ProbuildsGrid));
