import * as actions from '../actionTypes';
import {fetchAndUpdateResource} from './common'

import {putResource, deleteResource} from './rest'

export const updateMaps = payload => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_MAPS,
      payload,
    });
  };
}

const TYPE = "maps"

export const fetchAndUpdateMaps= () => {
  const [type, updateMethod] = [
    TYPE, 
    (response) => updateMaps(response.list)

  ]
  return fetchAndUpdateResource({type, updateMethod })
}  

export const putMap = ({map, ListOfMaps, index}) => {
  const [resource, list, type, updateMethod] = [
    map,
    ListOfMaps,
    TYPE,
    updateMaps
  ]
  return putResource({resource, list, type, index, updateMethod})
}

export const deleteMap = ({map, otherMaps}) => {
  const [resource, type, others, updateMethod] = [
    map,
    TYPE,
    otherMaps,
    updateMaps
  ]
  return deleteResource({resource, type, others, updateMethod})
}