import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';

function generateStyles(theme) {
  return {};
}
class Button extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, label, onClick, type, style, stretch } = this.props;
    const transformedStyle  = Array.isArray(style) ? Object.assign({}, ...style) : style;
    return (
      <button
        type={type}
        onClick={onClick}
        style={[
          gstyles.caption,
          {
            color: theme.light(),
            borderRadius: theme.borderRadius,
            background: theme.green(),
            alignItems: 'center',
            justifyContent: 'center',
            height: 34,
            cursor: 'pointer',
            border: 0,
            width: stretch ? '100%' : 'auto',
          },
          transformedStyle
        ]}>
        {label}
      </button>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(Radium(Button));
