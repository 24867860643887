import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import { updateProbuildsEditor, updateProbuilds } from 'common/reducers/product/lol/actions';
import BlitzAPI from 'common/api/BlitzAPI';

function generateStyles(theme) {
  return {};
}
class ProbuildsGridItem extends Component {
  constructor(props) {
    super(props);
  }
  onDelete = item => {
    const { updateProbuilds, setUndoItem } = this.props;
    BlitzAPI.deleteProbuilds(item.id).then(() => {
      updateProbuilds();
      setUndoItem(item);
    });
  };
  render() {
    const { gstyles, theme, styles, item, updateProbuildsEditor, teams } = this.props;
    let team = item.team_id !== null ? teams.filter((e) => e.id === item.team_id)[0] : null;
    return (
      <View style={{ width: 100, minHeight: 165, cursor: 'pointer' }}>
        <View
          onClick={() => updateProbuildsEditor(item)}>
          <div style={[gstyles.p1, { color: theme.text() }, gstyles.bottom_4]}>{item.name}</div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>
            {item.region}
          </div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>
            accounts: {item.accounts.length}
          </div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>
            id: {item.id}
          </div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>
            Fame: {item.fame}
          </div>
          <div style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4]}>
            Team: {team ? team.name : '-'}
          </div>
        </View>
        <div
          class="hvr-color-red"
          onClick={() => this.onDelete(item)}
          style={[gstyles.p1, { color: theme.text(0.5) }, gstyles.bottom_4, gstyles.top_4]}>
          delete
        </div>
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps, { updateProbuildsEditor, updateProbuilds })(
  Radium(ProbuildsGridItem),
);
