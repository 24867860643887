import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import Spinner from 'react-spinkit';

function generateStyles(theme) {
  return {};
}
class Loader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, styles, loader, onError } = this.props;
    if (loader.isRequesting)
      return (
        <View style={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner fadeIn="none" name="double-bounce" color={theme.red()} />
        </View>
      );
    if (loader.error) return onError ? onError() : null;
    if (loader.isReceived) return this.props.children;
    return null;
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(Radium(Loader));
