import { DATA_BACKEND_URL, AUTH_TOKEN_CLIENT} from 'config'
import { push } from 'react-router-redux';
import notifier from 'common/utils/notifier'
import resourceId from './id'

const VALORANT = "valorant"
const POST = "POST"

const createResource = ({
  game = VALORANT,
  type,
  data,
  updateFn,
}) => (dispatch) => {
  const reqBody = {
    data: data,
    authTokenClient: AUTH_TOKEN_CLIENT,
  };

  return fetch(`${DATA_BACKEND_URL}/${game}/${type}`, {
      method: POST,
      body: JSON.stringify(reqBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      if (parseInt(res.status) >= 400) {
        notifier.error(`Create failed (${res.status})`, res) 
        return
      }  
      return res.json()
   }).then((json) => {  
      const created = json.data
      dispatch(updateFn(created));
      dispatch(push(`/${game}/${type}`))
    })
    .catch((error) => {
      console.log(error);
    });
}

export default createResource