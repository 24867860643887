export const Champion = `query Champion($id: ID) {
  champion(id: $id) {
    id
    name
    tags {
      tag
    }
  }
}`;

export const Champions = `query Champion {
  champions {
    id
    name
    tags {
      tag
    }
  }
}`;
