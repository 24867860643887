import { combineReducers } from 'redux';
import * as ActionTypes from './actionTypes';

function toast(state = null, action) {
  switch(action.type) {
    case ActionTypes.UPDATE_TOAST:
      return action.toast;
    default:
      return state;
  }
}

export default combineReducers({ toast });
