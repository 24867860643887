import Home from 'view/browser/app_home/Home';
import Signin from 'view/browser/app_signin/Signin';
import Probuilds from 'view/browser/lol_probuilds/Probuilds';
import Teams from 'view/browser/lol_teams/Teams';
import ValorantAgents from 'view/browser/valorant_agents/ValorantAgents';
import AgentDetails from 'view/browser/valorant_agents/AgentDetails';
import ValorantWeapons from 'view/browser/valorant_weapons/ValorantWeapons';
import WeaponDetails from 'view/browser/valorant_weapons/WeaponDetails';
import ValorantMaps from 'view/browser/valorant_maps/ValorantMaps';
import MapDetails from 'view/browser/valorant_maps/MapDetails';
import ValorantTips from 'view/browser/valorant_tips/ValorantTips';
import TipDetails from 'view/browser/valorant_tips/TipDetails';
import ValorantHeadshots from 'view/browser/valorant_headshots/ValorantHeadshots';
import HeadshotDetails from 'view/browser/valorant_headshots/HeadshotDetails';

const routes = [
  {
    path: '/signin',
    component: Signin,
  },
  {
    path: '/',
    component: Home,
    routes: [
      {
        path: '/lol/probuilds',
        component: Probuilds,
      },
      {
        path: '/lol/teams',
        component: Teams,
      },
      {
        path: '/valorant/agents/:agent',
        component: AgentDetails,
      },
      {
        path: '/valorant/agents',
        component: ValorantAgents,
      },
      {
        path: '/valorant/weapons',
        component: ValorantWeapons,
      },
      {
        path: '/valorant/weapondetails',
        component: WeaponDetails,
      },
      {
        path: '/valorant/maps',
        component: ValorantMaps,
      },
      {
        path: '/valorant/mapdetails',
        component: MapDetails,
      },
      {
        path: '/valorant/tips/:id',
        component: TipDetails,
      },
      {
        path: '/valorant/tips',
        component: ValorantTips,
      },
      {
        path: '/valorant/headshots/:id',
        component: HeadshotDetails,
      },
      {
        path: '/valorant/headshots',
        component: ValorantHeadshots,
      },
    ]
  },
]

export default routes;
