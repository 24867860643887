import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';

function generateStyles(theme) {
  return {};
}
class Box extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, styles, style } = this.props;
    return (
      <View
        style={[{
          background: theme.bg2(),
          borderRadius: theme.borderRadius,
          border: `1px solid ${theme.borderColor}`,
          // boxShadow: `0px 1px ${theme.borderColor}`,
          padding: theme.spacing_1,
        }, gstyles.bottom_2, style]}>
        {this.props.children}
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: ownProps.dark ? state.settings.dark_theme : state.settings.theme,
    gstyles: ownProps.dark ? state.settings.dark_gstyles : state.settings.gstyles,
    styles: stylesSelector(ownProps.dark ? state.settings.dark_theme : state.settings.theme),
  };
}

export default connect(mapStateToProps)(Radium(Box));
