import { combineReducers } from 'redux';
import * as ActionTypes from './actionTypes';
import lol from './lol/reducer';
import fn from './fn/reducer';
import valorant from './valorant/reducer';

function metrics(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_METRICS:
      return action.metrics;
    default:
      return state;
  }
}

export default combineReducers({ metrics, lol, fn, valorant });
