import Home from 'view/mobile/home/Home';

const routes = [
  {
    path: '/',
    component: Home,
    loadData: () => {},
  }
]

export default routes;
