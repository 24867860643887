import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { fetchAndUpdateHeadshots, deleteHeadshot} from 'common/reducers/product/valorant/actions';
import { connect } from 'react-redux';

class ValorantHeadshots extends Component {

  componentDidMount() {
    this.props.dispatch(fetchAndUpdateHeadshots());
  }

  handleDeleteHeadshot(target) {
    const otherHeadshots = this.props.ListOfHeadshots.filter((headshot) => headshot.id !== target.id);
    this.props.dispatch(deleteHeadshot({ headshot: target, otherHeadshots }))
  }

  render() {
    const { ListOfHeadshots } = this.props;

    return (
      <React.Fragment>
        <div className="add-content-list-item-button">
          <Link
            to={{
              pathname: `/valorant/headshots/${Math.random().toString(36).slice(2)}`,
            }}
          >
            <button className="btn btn-larg btn-primary btn-block">
              Add New Headshot
            </button>
          </Link>
        </div>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Title</th>
              <th>Side</th>
              <th>Map</th>
              <th>Range</th>
            </tr>
          </thead>
          <tbody>
            {ListOfHeadshots.map((headshot, index) => (
              <tr key={`${headshot.id}`}>
                <td>{headshot.title}</td>
                <td>{headshot.side}</td>
                <td>{headshot.map}</td>
                <td>{headshot.range}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/valorant/headshots/${headshot.id}`,
                    }}
                  >
                    <i className="fa fa-pencil edit-icon" aria-hidden="true" />
                  </Link>
                </td>
                <td>
                  <i
                    onClick={() => this.handleDeleteHeadshot(headshot)}
                    className="fa fa-trash delete-icon"
                    aria-hidden="true"
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot />
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ListOfHeadshots: state.product.valorant.headshots
})

export default connect(mapStateToProps)(ValorantHeadshots);
