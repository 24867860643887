import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import moment from 'moment';
function generateStyles(theme) {
  return {};
}
class Input extends Component {
  render() {
    const { gstyles, theme, styles, type, label, style, isDate } = this.props;
    const { input: { value, onChange } } = this.props;
    return (
      <View style={style}>
        {label && (
          <div style={[gstyles.caption, { color: theme.text(0.5) }, gstyles.bottom_4]}>{label}</div>
        )}
        <input
          type={type}
          style={[
            gstyles.p1,
            {
              background: theme.bg(),
              color: theme.text(),
              borderRadius: theme.borderRadius,
              height: 34,
              paddingLeft: theme.spacing_2,
              paddingRight: theme.spacing_2,
              border: 0,
            },
          ]}
          value={value}
          onChange={onChange}
        />
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(Radium(Input));
