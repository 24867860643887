const LOL_CHAMPION_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/champion/`;
const LOL_CHAMPION_SPLASH_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/`;
const LOL_SPELL_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/spell/`;
const LOL_PASSIVE_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/passive/`;
const LOL_ITEM_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/item/`;
const LOL_MASTERIES_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/mastery/`;
const LOL_RUNES_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/rune/`;
const LOL_PROFILE_CDN = (patch) =>
  `https://ddragon.leagueoflegends.com/cdn/${patch}/img/profileicon/`;
const LOL_MEDALS = (patch) =>
  `https://s3-us-west-2.amazonaws.com/blitz-client-static-all/ranks/`;

class Static {
  constructor() {
    this.patch = '8.1';
  }
  setPatch(patch) {
    this.patch = patch;
  }
  getChampionImageById(champions, championId, patch = null) {
    if (!champions || championId === -1 || !champions.data) return null;
    const name = champions.keys[championId];
    return `${LOL_CHAMPION_CDN(patch || this.patch)}${name}.png`;
  }
  getChampionSplashImageById(champions, championId, patch = null) {
    if (!champions || championId === -1 || !champions.data) return null;
    const name = champions.keys[championId];
    return `${LOL_CHAMPION_SPLASH_CDN(patch || this.patch)}${name}_0.jpg`;
  }
  getSpellImageById(spells, spellId, patch = null) {
    if (!spells || spellId === -1 || !spells.data) return null;
    const data = spells.data;
    const name = Object.keys(data).find((key) => {
      return data[key].id === spellId;
    });
    return `${LOL_SPELL_CDN(patch || this.patch)}${name}.png`;
  }
  getChampionSplashImage(name, patch = null) {
    if (name === null) return null;
    return `${LOL_CHAMPION_SPLASH_CDN(patch || this.patch)}${name}_0.jpg`;
  }

  getChampionSpellImageById(spellId, patch = null) {
    if (spellId === -1) return null;
    return `${LOL_SPELL_CDN(patch || this.patch)}${spellId}.png`;
  }

  getChampionPassiveImageById(passiveId, patch = null) {
    if (passiveId === '') return null;
    return `${LOL_PASSIVE_CDN(patch || this.patch)}${passiveId}`;
  }

  getAbilityImage(name, patch = null) {
    return `${LOL_SPELL_CDN(patch || this.patch)}${name}.png`;
  }

  getItem(itemId, patch = null) {
    if (itemId === 0) return null;
    return `${LOL_ITEM_CDN(patch || this.patch)}${itemId}.png`;
  }
  getProfileIcon(icon, patch = null) {
    if (icon === 0) return null;
    return `${LOL_PROFILE_CDN(patch || this.patch)}${icon}.png`;
  }
  getChampionImage(name, patch = null) {
    if (name === null) return null;
    return `${LOL_CHAMPION_CDN(patch || this.patch)}${name}.png`;
  }

  getMedalIcons(currentLeague) {
    if (!currentLeague) return `${LOL_MEDALS()}default.png`;
    if (
      currentLeague.tier === 'CHALLENGER' ||
      currentLeague.tier === 'MASTER'
    ) {
      return `${LOL_MEDALS()}${currentLeague.tier.toLowerCase()}_1.png`;
    }
    return `${LOL_MEDALS()}${currentLeague.tier.toLowerCase()}_${
      currentLeague.rank
    }.png`;
  }
}

export default new Static();
