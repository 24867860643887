import { combineReducers } from 'redux';
import app from './app/reducer';
import settings from './app/settings';
import loading from './loading/reducer';
import product from './product/reducer';
import content from './content/reducer';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';


const reducers = combineReducers({
  settings,
  app,
  product,
  content,
  loading,
  router: routerReducer,
  form: formReducer,
});

export default reducers;
