import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Damage from './Damage.js';

class WeaponDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      type: '',
      cost: '',
      primary: {
        kind: '',
        rate: '',
        video: '',
        videoWebm: '',
      },
      alt: {
        kind: '',
        rate: '',
        video: '',
        videoWebm: '',
      },
      magazine: '',
      damage: [],
      damageCaveat: '',
      penetration: '',
      pelletCount: '',
      images: {
        model: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDamageChange = this.handleDamageChange.bind(this);
    this.handleAddDamageType = this.handleAddDamageType.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state)
      this.setState({
        name: this.props.location.state.name,
        type: this.props.location.state.type,
        cost: this.props.location.state.cost,
        primary: this.props.location.state.primary,
        alt: this.props.location.state.alt,
        magazine: this.props.location.state.magazine,
        damage: this.props.location.state.damage,
        damageCaveat: this.props.location.state.damageCaveat,
        penetration: this.props.location.state.penetration,
        pelletCount: this.props.location.state.pelletCount,
        images: this.props.location.state.images,
        _id: this.props.location.state._id
      });
  }

  handleChange(event) {
    const target = event.target;
    const primary = this.state.primary;
    const alt = this.state.alt;
    const images = this.state.images;
    switch (target.name) {
      case 'name':
        this.setState({ name: target.value });
        break;
      case 'type':
        this.setState({ type: target.value });
        break;
      case 'cost':
        this.setState({ cost: target.value });
        break;
      case 'primaryKind':
        primary.kind = target.value;
        this.setState({ primary });
        break;
      case 'primaryRate':
        primary.rate = target.value;
        this.setState({ primary });
        break;
      case 'primaryVideo':
        primary.video = target.value;
        this.setState({ primary });
        break;
      case 'primaryVideoWebM':
        primary.videoWebm = target.value;
        this.setState({ primary });
        break;
      case 'altKind':
        alt.kind = target.value;
        this.setState({ alt });
        break;
      case 'altRate':
        alt.rate = target.value;
        this.setState({ alt });
        break;
      case 'altVideoWebM':
        alt.videoWebm = target.value;
        this.setState({ alt });
        break;
      case 'altVideo':
        alt.video = target.value;
        this.setState({ alt });
        break;
      case 'magazine':
        this.setState({ magazine: target.value });
        break;
      case 'damageCaveat':
        this.setState({ damageCaveat: target.value });
        break;
      case 'penetration':
        this.setState({ penetration: target.value });
        break;
      case 'pelletCount':
        this.setState({ pelletCount: target.value });
        break;
      case 'imageModel':
        images.model = target.value;
        this.setState({ images });
        break;
      default:
        break;
    }
  }

  handleDamageChange(newDamageType, damageId) {
    const damage = this.state.damage.map((damageType, index) =>
      index == damageId ? newDamageType : damageType
    );
    this.setState({ damage });
  }

  handleAddDamageType(event) {
    event.preventDefault();
    const newDamageType = { range: '', body: '', head: '', leg: '' };
    const damage = this.state.damage.concat(newDamageType);
    this.setState({ damage });
  }

  render() {
    const {
      name,
      type,
      cost,
      primary,
      alt,
      magazine,
      damage,
      damageCaveat,
      penetration,
      pelletCount,
      images,
    } = this.state;
    console.log(this.state, primary);
    return (
      <form>
        <h3>Basic Details</h3>
        <div className="form-group">
          <div className="row">
            <div className="col">
              <label className="font-weight-bold">Name</label>
              <input
                className="form-control"
                name="name"
                type="text"
                value={name}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Type</label>
              <input
                className="form-control"
                name="type"
                type="text"
                value={type}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Cost</label>
              <input
                className="form-control"
                name="cost"
                type="text"
                value={cost}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Magazine Size</label>
              <input
                className="form-control"
                name="magazine"
                type="text"
                value={magazine}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Image</label>
              <input
                className="form-control"
                name="imageModel"
                type="text"
                value={images.model}
                onChange={this.handleChange}
              />
              {images.model && <img src={images.model} width="100" />}
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="form-group">
          <h3 className="font-weight-bold">Primary Fire</h3>
          <div className="weapon-fire">
            <div className="row">
              <div className="col">
                <label className="font-weight-bold">Kind</label>
                <input
                  className="form-control"
                  placeholder="Kind"
                  name="primaryKind"
                  type="text"
                  value={primary.kind}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <label className="font-weight-bold">Rate</label>
                <input
                  className="form-control"
                  placeholder="Rate"
                  name="primaryRate"
                  type="text"
                  value={primary.rate}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label className="font-weight-bold">Video (mp4)</label>
                <input
                  className="form-control"
                  placeholder="Video MP4 Link"
                  name="primaryVideo"
                  type="text"
                  value={primary.video}
                  onChange={this.handleChange}
                />
                {primary.video && (
                  <video src={primary.video} width="200" controls></video>
                )}
              </div>
              <div className="col">
                <label className="font-weight-bold">Video (webm)</label>
                <input
                  className="form-control"
                  placeholder="Video WebM Link"
                  name="primaryVideoWebM"
                  type="text"
                  value={primary.videoWebm}
                  onChange={this.handleChange}
                />
                {primary.videoWebm && (
                  <video src={primary.videoWebm} width="200" controls></video>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <h3 className="font-weight-bold">Alt Fire</h3>
          <div className="weapon-fire">
            <div className="row">
              <div className="col">
                <label className="font-weight-bold">Kind</label>
                <input
                  className="form-control"
                  placeholder="Kind"
                  name="altKind"
                  type="text"
                  value={alt.kind}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <label className="font-weight-bold">Rate</label>
                <input
                  className="form-control"
                  placeholder="Rate"
                  name="altRate"
                  type="text"
                  value={alt.rate}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label className="font-weight-bold">Video (mp4)</label>
                <input
                  className="form-control"
                  placeholder="Video"
                  name="altVideo"
                  type="text"
                  value={alt.video}
                  onChange={this.handleChange}
                />
                {alt.video && (
                  <video src={alt.video} width="200" controls></video>
                )}
              </div>
              <div className="col">
                <label className="font-weight-bold">Video (webm)</label>
                <input
                  className="form-control"
                  placeholder="Video"
                  name="altVideoWebM"
                  type="text"
                  value={alt.videoWebm}
                  onChange={this.handleChange}
                />
                {alt.videoWebm && (
                  <video src={alt.videoWebm} width="200" controls></video>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="form-group">
          <label className="font-weight-bold">Damage Types</label>

          {damage.map((damageType, index) => (
            <React.Fragment key={index}>
              <Damage
                key={index}
                damageId={index}
                damageType={damageType}
                handleDamageChange={this.handleDamageChange}
              />
              <hr />
            </React.Fragment>
          ))}
          <button
            className="btn btn-dark btn-sm"
            onClick={this.handleAddDamageType}
          >
            Add New Damage Type
          </button>
        </div>
        <br />
        <hr />
        <br />
        <div className="form-group">
          <div className="row">
            <div className="col">
              <label className="font-weight-bold">Damage Caveat</label>
              <input
                className="form-control"
                name="damageCaveat"
                type="text"
                value={damageCaveat}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Penetration</label>
              <input
                className="form-control"
                name="penetration"
                type="text"
                value={penetration}
                onChange={this.handleChange}
              />
            </div>
            <div className="col">
              <label className="font-weight-bold">Pellet Count</label>
              <input
                className="form-control"
                name="pelletCount"
                placeholder="Applicable only if Damage Caveat is per bullet"
                type="text"
                value={pelletCount}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <div className="form-group">
          <Link
            to={{
              pathname: '/valorant/weapons',
              state: {
                weapon: this.state,
                id: this.props.location.state && this.props.location.state.id,
              },
            }}
          >
            <button className="btn btn-lg btn-primary btn-block">Submit</button>
          </Link>
        </div>
      </form>
    );
  }
}

export default WeaponDetails;
