import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';

function generateStyles(theme) {
  return {}
}
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.mounted = true;
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.collapse = this.collapse.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  }
  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }
  handleDocumentClick(event) {
    if (this.mounted) {
      if (!ReactDOM.findDOMNode(this).contains(event.target)) {
        if (this.state.isOpen) {
          this.setState({ isOpen: false });
        }
      }
    }
  }
  collapse() {
    this.setState({ isOpen: false });
  }
  render() {
    const {
      gstyles,
      theme,
      styles,
      options,
      renderSelectedOption,
      renderDropdown,
      height,
      dropdownStyle,
      inverted,
    } = this.props;
    return (
      <View style={{ position: 'relative' }}>
        <div
          style={{ cursor: 'pointer', height }}
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        >
          {renderSelectedOption()}
        </div>
        {this.state.isOpen && (
          <div
            style={[
              {
                position: 'absolute',
                zIndex: 10,
              },
              inverted ? { bottom: height } : { top: height },
              dropdownStyle,
            ]}
          >
            {renderDropdown(options, this.collapse)}
          </div>
        )}
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(
  mapStateToProps,
)(Radium(Dropdown));
