import notifier from "simple-react-notifications2";
import "simple-react-notifications2/dist/index.css";

notifier.configure({
  autoClose: 2000,
  delay: 500,
  single: true,
  width: "480px"
});

const wrapper = {
  success: msg => notifier.success(msg),
  error:(msg, resp) => notifier.error(msg) && console.log(`${msg} => ${resp.statusText}`)
}

export default wrapper