import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View, Input, Button } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import { Field, reduxForm } from 'redux-form';

function generateStyles(theme) {
  return {};
}
class SignInFrom extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, styles, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field name="email" component={Input} label="Email" />
        <div style={{ height: theme.spacing_2 }}/>
      <Field name="password" component={Input} type="password" label="Password" />
        <div style={{ height: theme.spacing_2 }}/>
        <Button stretch type="submit" onClick={handleSubmit} label={"Sign In"}/>
      </form>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'signin',
  })(Radium(SignInFrom)),
);
