import * as ActionTypes from './actionTypes';
import BlitzAPI from 'common/api/BlitzAPI';

export function signIn(email, password) {
  return dispatch =>
    BlitzAPI.postLogin({
      user: {
        email,
        password,
      },
    }).then(response => {
      const user = response.data;
      dispatch({
        type: ActionTypes.SIGN_IN,
        user,
      })
      return user;
    });

  // fetch('https://data.iesdev.com/api/login', {
  //     method: 'POST',
  //     body: JSON.stringify({ username: email, password }),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(result => {
  //       const user = result.userData;
  //       dispatch({
  //         type: ActionTypes.SIGN_IN,
  //         user,
  //       })
  //       return user;
  //     });
}

export function logout() {
  return {
    type: ActionTypes.LOGOUT
  }
}
