import { combineReducers } from 'redux';
import * as ActionTypes from './actionTypes';

function champions(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_CHAMPIONS:
      return action.champions;
    default:
      return state;
  }
}
function items(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_ITEMS:
      return action.items;
    default:
      return state;
  }
}
function runes(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_RUNES:
      return action.runes;
    default:
      return state;
  }
}
function spells(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_SPELLS:
      return action.spells;
    default:
      return state;
  }
}
function currentPatch(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_CURRENT_PATCH:
      return action.currentPatch;
    default:
      return state;
  }
}
function patch(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_PATCH:
      return action.patch;
    default:
      return state;
  }
}
function championTips(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_CHAMPION_TIPS:
      return action.tips;
    default:
      return state;
  }
}
function probuilds(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_PROBUILDS:
      return action.probuilds;
    default:
      return state;
  }
}
function teams(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_TEAMS:
      return action.teams;
    default:
      return state;
  }
}
function tiers(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_TIERS:
      return action.tiers;
    default:
      return state;
  }
}
function probuildsEditor(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_PROBUILDS_EDITOR:
      return action.probuilds;
    default:
      return state;
  }
}
function teamsEditor(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_TEAMS_EDITOR:
      return action.teams;
    default:
      return state;
  }
}
function metareport(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_META_REPORT:
      return action.metareport;
    default:
      return state;
  }
}

function toast(state = null, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_TOAST:
      return action.toast;
    default:
      return state;
  }
}

export default combineReducers({
  championTips,
  champions,
  items,
  runes,
  spells,
  currentPatch,
  patch,
  tiers,
  probuilds,
  probuildsEditor,
  metareport,
  toast,
  teams,
  teamsEditor,
});
