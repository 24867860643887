import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { FieldArray, Field, reduxForm } from 'redux-form';
import { Input, View, Button, Add, FileInput, Dropdown } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import { REGIONS } from 'view/global/utils/LeagueConstants';
import MdDelete from 'react-icons/lib/md/delete';
import ProbuildsAccountField from './ProbuildsAccountField';
import moment from 'moment';

function generateStyles(theme) {
  return {};
}

const renderMembers = props => {
  const { fields, meta: { touched, error }, gstyles, theme } = props;
  return (
    <View>
      {fields.map((member, index) => (
        <View row key={member} style={[gstyles.bottom_4, { alignItems: 'center' }]}>
          <Field
            name={`${member}.name`}
            type="text"
            component={Input}
            style={[{ flex: 1 }, gstyles.right_4]}
          />
          <Field name={`${member}.region`} component="select">
            {Object.entries(REGIONS).map(e => {
              return (
                <option key={e[1]} value={e[1]}>
                  {e[0]}
                </option>
              );
            })}
            <option />
          </Field>
          <MdDelete
            onClick={() => fields.remove(index)}
            color={theme.text(0.1)}
            size={24}
            style={{ cursor: 'pointer', ...gstyles.left_4 }}
          />
        </View>
      ))}
      <Add onClick={() => fields.push({ name: '', region: 'na1' })} />
      {touched && error && <span>{error}</span>}
    </View>
  );
};

const renderAccounts = props => {
  const { fields, meta: { touched, error }, gstyles, theme } = props;
  return (
    <View>
      {fields.map((member, index) => {
        return (
          <View row key={member} style={[gstyles.bottom_4, { alignItems: 'center' }]}>
            <Field
              name={`${member}`}
              type="text"
              component={ProbuildsAccountField}
              style={[{ flex: 1 }, gstyles.right_4]}
            />
            <MdDelete
              onClick={() => fields.remove(index)}
              color={theme.text(0.1)}
              size={24}
              style={{ cursor: 'pointer', ...gstyles.left_4 }}
            />
          </View>
        );
      })}
    </View>
  );
};

class ProbuildsForm extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.initialize(this.props.initialValues); // here add this line to initialize the form
  }
  render() {
    const { gstyles, theme, styles, handleSubmit, error, updatedAt, reset, teams } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4]}>Name</div>
        <Field name={`name`} type="text" component={Input} style={{ flex: 1 }} />
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4,gstyles.top_1]}>Full Name</div>
        <Field name={`real_name`} type="text" component={Input} style={{ flex: 1 }} />
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4,gstyles.top_1]}>Portrait Image URL</div>
        <Field name={`portrait_image_url`} type="text" component={Input} style={{ flex: 1 }} />
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4,gstyles.top_1]}>Profile Image URL</div>
        <Field name={`profile_image_url`} type="text" component={Input} style={{ flex: 1 }} />
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>Team</div>
        <Field name={`team_id`} component="select">
          {teams.map((team, index) => {
            return (<option key={index} value={team.id}>{team.name}</option>)
          })}
          <option />
        </Field>
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>Region</div>
        <Field name={`region`} component="select">
          {Object.entries(REGIONS).map(e => {
            return (
              <option key={e[1]} value={e[1]}>
                {e[0]}
              </option>
            );
          })}
          <option />
        </Field>
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>Fame Level</div>
        <Field name={`fame`} parse={value => Number(value)} component="select" style={{width: 50}}>
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </Field>
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>
          Accounts
        </div>
        <FieldArray name="accounts" component={renderAccounts} {...this.props} />
        <FieldArray name="accounts_name" component={renderMembers} {...this.props} />
        <View row style={gstyles.top_2}>
          <Button type={'submit'} label={'Submit'} style={gstyles.right_4} />
          <Button type="button" onClick={reset} label={'Clear'} />
        </View>
        <div style={[gstyles.caption, { color: theme.text() }, gstyles.top_2]}>
          {updatedAt && moment(updatedAt).format('MMMM Do, h:mm:ss a')}
        </div>
        {error && <div style={[gstyles.p1, { color: theme.red() }, gstyles.top_2]}>{error}</div>}
      </form>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'probuilds',
    enableReinitialize: true,
  })(Radium(ProbuildsForm)),
);
