import * as actions from '../actionTypes';
import {fetchAndUpdateResource} from './common'
import {ASC} from './rest/helpers/constants'
import {putResource, deleteResource} from './rest'

export const updateGuides = payload => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_GUIDES,
      payload,
    });
  };
}

const TYPE = "tips"

export const fetchAndUpdateGuides= () => {
  const [type, updateMethod] = [
    TYPE, 
    (response) => updateGuides(response.list)

  ]
  return fetchAndUpdateResource({type, updateMethod })
}  

export const putGuide = ({tip, ListOfTips, index, lastPinned}) => {
  const [resource, list, type,  updateMethod] = [
    tip, 
    ListOfTips,
    TYPE,
    updateGuides
  ]
  return putResource({resource, list, type, index, updateMethod, options:{order:ASC, lastPinned}})
}

export const deleteGuide = ({tip, otherTips}) => {
  const [resource, type, others, updateMethod] = [
    tip,
    TYPE,
    otherTips,
    updateGuides
  ]
  return deleteResource({resource, type, others, updateMethod})
}