import {ASC} from './constants'
import {isPinned} from 'common/utils/pinned'

const updateFn = ({list, index, updateMethod, options={} }) => (updated) => {
  if (Array.isArray(list)) {
    let newState = list.slice()
    if (index) {
      if (isPinned(updated)) {
        newState.splice(index, 1)
        if (options.lastPinned != -1) {
          newState.splice(options.lastPinned, 0, updated)
        } else {
          newState = [updated, ...newState]
        }
      } else {
       newState[index] = updated
      }
    } else {
      if (options.order === ASC) {
        newState = [updated, ...newState]
      } else {
        newState = [...newState, updated]
      }
    }
    return updateMethod(newState)
  } else {
    return updateMethod({
      updated,
      list,
      index
    })
  }
}

export default updateFn