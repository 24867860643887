import { DATA_BACKEND_URL} from 'config'
import getTimestamp from 'common/utils/getTimestamp';

const fetchAndUpdateResource = ({type, updateMethod}) => {
  return async(dispatch) => {

    try {
      const response = await fetch(`${DATA_BACKEND_URL}/valorant/${type}.json?ver=${getTimestamp()}`);
      const result = await response.json()
      return dispatch(
        updateMethod(result)
      );
    } catch (error) {
      console.log('Error in fetching Data', error);
    }
  };
};

export {
  fetchAndUpdateResource
}