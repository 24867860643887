import * as actions from '../actionTypes';
import {fetchAndUpdateResource} from './common'

import {putResource, createResource, deleteResource} from './rest'

export const updateAgents = payload => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_AGENTS,
      payload,
    });
  };
}

const TYPE = "agents"

export const fetchAndUpdateAgents= () => {
  const [type, updateMethod] = [
    TYPE, 
    (response) => updateAgents(response.list)

  ]
  return fetchAndUpdateResource({type, updateMethod })
}  

export const putAgent = ({agent, ListOfAgents, index}) => {
  const [resource, list, type, updateMethod] = [
    agent, 
    ListOfAgents,
    TYPE,
    updateAgents
  ]
  return putResource({resource, list, type, index, updateMethod})
}

export const createAgent = ({agent, otherAgents}) => {
  const [resource, type, others, updateMethod] = [
    agent,
    TYPE,
    otherAgents,
    updateAgents
  ]
  return createResource({resource, type, others, updateMethod})
}

export const deleteAgent = ({agent, otherAgents}) => {
  const [resource, type, others, updateMethod] = [
    agent,
    TYPE,
    otherAgents,
    updateAgents
  ]
  return deleteResource({resource, type, others, updateMethod})
}