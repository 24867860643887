import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Switch, withRouter } from 'react-router-dom';
import { View, RouteWithSubRoutes, Loader } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import routes from './routes';

function generateStyles(theme) {
  return {};
}
class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, styles, loader, lol } = this.props;
    return (
      <Loader loader={loader}>
        {loader.isReceived && (
          <View style={{ height: '100%', width: '100%' }}>
            <Switch>
              {routes.map((route, i) => {
                return <RouteWithSubRoutes key={i} {...route} />;
              })}
            </Switch>
          </View>
        )}
      </Loader>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    loader: state.loading.init,
    lol: state.product.lol,
  };
}

export default withRouter(connect(mapStateToProps)(Radium(App)));
