import updateResource from './helpers/update'
import createResource from './create'
import resourceId from './helpers/id'
import updateFn from './helpers/updatefn'

const method ="PUT"

const putResource = ({resource, list, type, index, updateMethod, options }) => {
  const id = resourceId(resource)
  if (!id) return createResource({resource, type, list, updateMethod, options})

  const data = resource
  
  return updateResource({
    resource, 
    type, 
    data, 
    updateFn: updateFn({list, index, updateMethod, options}), 
    method
  })
}

export default putResource