import React, { Component } from 'react';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Radium from "radium";
import { Input, View, Button, Add, FileInput, Dropdown } from 'view/global/components';
import { REGIONS } from 'view/global/utils/LeagueConstants';
import moment from 'moment';


import { generateStylesSelector } from 'view/global/utils/selectors';


class TeamsForm extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    this.props.initialize(this.props.initialValues); // here add this line to initialize the form
  }

  render() {
    const { handleSubmit, gstyles, theme, reset, updatedAt, error } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4]}>Name</div>
        <Field name={`name`} type="text" component={Input} style={{ flex: 1 }} />
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>Picture Filename</div>
        <Field name={`picture_url`} type="text" component={Input} style={{ flex: 1 }} />
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>Region</div>
        <Field name={`region`} component="select">
          {Object.entries(REGIONS).map(e => {
            return (
              <option key={e[1]} value={e[1]}>
                {e[0]}
              </option>
            );
          })}
          <option />
        </Field>
        <div style={[gstyles.p1_bold, { color: theme.text() }, gstyles.bottom_4, gstyles.top_1]}>Tag</div>
        <Field name={`tag`} type="text" component={Input} style={{ flex: 1 }} />
        <View row style={gstyles.top_2}>
          <Button type={'submit'} label={'Submit'} style={gstyles.right_4} />
          <Button type="button" onClick={reset} label={'Clear'} />
        </View>
        <div style={[gstyles.caption, { color: theme.text() }, gstyles.top_2]}>
          {updatedAt && moment(updatedAt).format('MMMM Do, h:mm:ss a')}
        </div>
        {error && <div style={[gstyles.p1, { color: theme.red() }, gstyles.top_2]}>{error}</div>}
      </form>
    );
  }
}

function generateStyles(theme) {
  return {};
}
const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme)
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'teams',
    enableReinitialize: true,
  })(Radium(TeamsForm)),
);
