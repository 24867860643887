import {
  combineReducers
} from 'redux';
import * as actions from './actionTypes';

const defaultWeapons = {
  Sidearms: [],
  SMGs: [],
  Rifles: [],
  Snipers: [],
  Shotguns: [],
  Heavy: []
}

function agents(state = [], action) {
  switch (action.type) {
    case actions.UPDATE_AGENTS:
      return [...action.payload];
    default:
      return state;
  }
}

function maps(state = [], action) {
  switch (action.type) {
    case actions.UPDATE_MAPS:
      return [...action.payload];
    default:
      return state;
  }
}

function weapons(state = defaultWeapons, action) {
  switch (action.type) {
    case actions.UPDATE_WEAPONS:
      return {...action.payload
      };
    default:
      return state;
  }
}

function guides(state = [], action) {
  switch (action.type) {
    case actions.UPDATE_GUIDES:
      return [...action.payload];
    default:
      return state;
  }
}

function headshots(state = [], action) {
  switch (action.type) {
    case actions.UPDATE_HEADSHOTS:
      return [...action.payload];
    default:
      return state;
  }
}

export default combineReducers({
  agents,
  weapons,
  maps,
  guides,
  headshots,
});