import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View, Box } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import { signIn } from 'common/reducers/app/actions';
import { push } from 'react-router-redux';
import SignInForm from './SignInForm';

function generateStyles(theme) {
  return {}
}
class Signin extends Component {
  constructor(props) {
    super(props);
  }
  signIn = (response) => {
    const { email, password } = response;
    const { signIn, push } = this.props;
    signIn(email, password).then(() => {
      push('/valorant/tips');
    }).catch(() => {
      alert("Something went wrong. Try again")
    });
  }
  render() {
    const { gstyles, theme, styles } = this.props;
    return (
      <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
        <Box>
          <View style={{ width: 330 }}>
            <div style={[gstyles.p1, { color: theme.text() }]}>Sign In</div>
            <div style={[gstyles.line, gstyles.top_1, gstyles.bottom_1]} />
            <SignInForm onSubmit={this.signIn}/>
          </View>
        </Box>
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default connect(
  mapStateToProps,
  { signIn, push }
)(Radium(Signin));
