import BlitzAPI from 'common/api/BlitzAPI';

const middleware = store => next => action => {
  const currState = store.getState();
  const result = next(action);
  const nextState = store.getState();
  const dispatch = store.dispatch;

  if (currState.settings.user !== nextState.settings.user) {
    nextState.settings.user ? BlitzAPI.setToken(nextState.settings.user.token) : BlitzAPI.setToken(null)
  }
}
export default middleware;
