import React, { Component } from 'react';

class POI extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', top: '', left: '', video: '' };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.poi);
  }

  handleChange(event) {
    const target = event.target;
    switch (target.name) {
      case 'name':
        this.setState({ name: target.value }, () =>
          this.props.handlePoiChange(this.state, this.props.poiId)
        );
        break;
      case 'top':
        this.setState({ top: target.value }, () =>
          this.props.handlePoiChange(this.state, this.props.poiId)
        );
        break;
      case 'left':
        this.setState({ left: target.value }, () =>
          this.props.handlePoiChange(this.state, this.props.poiId)
        );
        break;
      case 'video':
        this.setState({ video: target.value }, () =>
          this.props.handlePoiChange(this.state, this.props.poiId)
        );
      default:
        break;
    }
  }

  render() {
    const { name, top, left, video } = this.state;
    return (
      <div className="row">
        <div className="col">
          <label className="font-weight-bold">POI Name</label>
          <input
            className="form-control"
            placeholder="Name"
            name="name"
            type="text"
            value={name}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">POI Y Pos.</label>
          <input
            className="form-control"
            placeholder="Top"
            name="top"
            type="text"
            value={top}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">POI X Pos</label>
          <input
            className="form-control"
            placeholder="Left"
            name="left"
            type="text"
            value={left}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <input
            className="form-control"
            placeholder="Video"
            name="video"
            type="text"
            value={video}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default POI;
