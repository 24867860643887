import * as actions from '../actionTypes';
import {fetchAndUpdateResource} from './common'
import {ASC} from './rest/helpers/constants'
import {putResource, deleteResource} from './rest'

export const updateHeadshots = payload => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_HEADSHOTS,
      payload,
    });
  };
}

const TYPE = "headshots"

export const fetchAndUpdateHeadshots= () => {
  const [type, updateMethod] = [
    TYPE, 
    (response) => updateHeadshots(response.list)

  ]
  return fetchAndUpdateResource({type, updateMethod })
}  

export const putHeadshot = ({headshot, ListOfHeadshots, index}) => {
  const [resource, list, type, updateMethod] = [
    headshot,
    ListOfHeadshots,
    TYPE,
    updateHeadshots
  ]
  return putResource({resource, list, type, index, updateMethod, options:{order:ASC}})
}

export const deleteHeadshot = ({headshot, otherHeadshots}) => {
  const [resource, type, others, updateMethod] = [
    headshot,
    TYPE,
    otherHeadshots,
    updateHeadshots
  ]
  return deleteResource({resource, type, others, updateMethod})
}