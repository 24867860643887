import * as actions from '../actionTypes';
import {fetchAndUpdateResource} from './common'

import {putResource, deleteResource} from './rest'

export const updateWeapons = payload => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_WEAPONS,
      payload,
    });
  };
}

const TYPE = "weapons"

export const fetchAndUpdateWeapons= () => {
  const [type, updateMethod] = [
    TYPE, 
    (response) => updateWeapons(response)
  ]
  return fetchAndUpdateResource({type, updateMethod })
}  

const weaponUpdateMethod = (weaponType) => ({ updated,list,index}) => {
  const newState = {...list}
  const id = index
  if (id) {
    newState[weaponType][id] = updated
  } else {
    newState[weaponType].push(updated)
  }
  return updateWeapons(newState)
}

export const putWeapon = ({weapon, weaponType, id, weapons}) => {
  const [resource, list, type, index, updateMethod] = [
    weapon,
    weapons,
    TYPE,
    id,
    weaponUpdateMethod(weaponType)
  ]
  return putResource({resource, list, type, index, updateMethod})
}

export const deleteWeapon = ({weapon, otherWeapons}) => {
  const [resource, type, others, updateMethod] = [
    weapon,
    TYPE,
    otherWeapons,
    updateWeapons
  ]
  return deleteResource({resource, type, others, updateMethod})
}