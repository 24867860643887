import React, { Component } from 'react';

class Damage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: '',
      body: '',
      head: '',
      leg: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.damageType);
  }

  handleChange(event) {
    const target = event.target;
    switch (target.name) {
      case 'range':
        this.setState({ range: target.value }, () =>
          this.props.handleDamageChange(this.state, this.props.damageId)
        );
        break;
      case 'body':
        this.setState({ body: target.value }, () =>
          this.props.handleDamageChange(this.state, this.props.damageId)
        );
        break;
      case 'head':
        this.setState({ head: target.value }, () =>
          this.props.handleDamageChange(this.state, this.props.damageId)
        );
        break;
      case 'leg':
        this.setState({ leg: target.value }, () =>
          this.props.handleDamageChange(this.state, this.props.damageId)
        );
        break;
      default:
        break;
    }
  }

  render() {
    const { range, body, head, leg } = this.state;
    return (
      <div className="row">
        <div className="col">
          <label className="font-weight-bold">Range</label>
          <input
            className="form-control"
            placeholder="Range"
            name="range"
            type="text"
            value={range}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">Head</label>
          <input
            className="form-control"
            placeholder="Head"
            name="head"
            type="text"
            value={head}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">Body</label>
          <input
            className="form-control"
            placeholder="Body"
            name="body"
            type="text"
            value={body}
            onChange={this.handleChange}
          />
        </div>

        <div className="col">
          <label className="font-weight-bold">Leg</label>
          <input
            className="form-control"
            placeholder="Leg"
            name="leg"
            type="text"
            value={leg}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default Damage;
