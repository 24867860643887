import { createSelector } from 'reselect';

// Generate Theme
export const generateStylesSelector = generateStyles => {
  const themeSelector = theme => theme;
  return createSelector(themeSelector, theme => {
    return generateStyles(theme);
  });
};

export const stylesSelector = generateStyles => {
  const themeSelector = theme => theme;
  return createSelector(themeSelector, theme => {
    return generateStyles(theme);
  });
};

const championId = (championId) => championId;
const champions = (championId, champions) => champions;
export const getChampion = createSelector(championId, champions, (championId, champions) => {
  const key = champions.keys[championId];
  return champions.data[key];
})
