import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAndUpdateHeadshots, fetchAndUpdateMaps, putHeadshot } from 'common/reducers/product/valorant/actions';

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

const SIDES = [
  {
    key: 'attacking',
    name: 'Attacking',
  },
  {
    key: 'defending',
    name: 'Defending',
  },
  {
    key: 'both',
    name: 'Both',
  },
];

const RANGES = [
  {
    key: 'short',
    name: '0-20m',
  },
  {
    key: 'medium',
    name: '21-40m',
  },
  {
    key: 'long',
    name: '41m+',
  },
];

const defaultState = {
  side: 'attacking',
  title: '',
  icon: '',
  type: 'video',
  video: '',
  description: '',
  map: '',
  range: 'short',
  location: {
    shotIcon: {
      y: 0,
      x: 0,
    },
    targetIcon: {
      y: 0,
      x: 0,
    },
    clip: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    clipSize: '',
    shotRange: 0,
    angle: 0,
  },
};

const cdnPrefix = 'https://blitz-cdn-videos.blitz.gg/';

const HeadshotDetails = (props) => {
  const {ListOfHeadshots, MapsList, dispatch} = props;
  const [uploading, setUploading] = useState(false);
  const [detailsState, setDetailsState] = useState();
  const [videoFile, setVideoFile] = useState(null);
  const [isShotSpot, setIsShotSpot] = useState(true);
  const [clipSize, setClipSize] = useState('none');
  const [wrongText, setWrongText] = useState('');
  const [isMovingClipBox, setIsMovingClipBox] = useState(false);
  const [moveStartPos, setMoveStartPos] = useState({});

  const mapElem = useRef(null);
  const shotXPosInput = useRef(null);
  const shotYPosInput = useRef(null);
  const targetXPosInput = useRef(null);
  const targetYPosInput = useRef(null);
  const clipXPosInput = useRef(null);
  const clipYPosInput = useRef(null);
  const clipWidthInput = useRef(null);
  const clipHeightInput = useRef(null);
  const clipElem = useRef(null);

  const smallClipRate = Number((86 / 106).toFixed(2));
  const bigClipRate = Number((164 / 106).toFixed(2));

  const [smallClip, setSmallClip] = useState({
    x: 35,
    y: 38,
    width: 30,
    height: 30 * smallClipRate,
  });

  const [bigClip, setBigClip] = useState({
    x: 35,
    y: 27,
    width: 30,
    height: 30 * bigClipRate,
  });

  const id = props.match.params.id;

  useEffect(() => {
    Promise.all([
      dispatch(fetchAndUpdateHeadshots()),
      dispatch(fetchAndUpdateMaps()),
    ])
  }, []);

  useEffect(() => {
    if (ListOfHeadshots && ListOfHeadshots.length) {
      setDetailsState(ListOfHeadshots.find(t => t.id === id) || { ...defaultState, id })
      const curHeadshot = ListOfHeadshots.find(t => t.id === id);
      if (curHeadshot) {
        setClipSize(curHeadshot.location.clipSize);
        if (curHeadshot.location.clipSize === 'small') {
          setSmallClip(curHeadshot.location.clip)
        } else {
          setBigClip(curHeadshot.location.clip)
        }
      }
    } else if (ListOfHeadshots.length < 1) {
      setDetailsState({ ...defaultState, id });
    };
  }, [id, ListOfHeadshots]);

  useEffect(() => {
    if (!detailsState) return;

    // calculate shot range
    let shotRange = 0;
    let offsetX = 0;
    let offsetY = 0;

    if (detailsState.location.shotIcon.y !== 0 && detailsState.location.targetIcon.y !== 0) {
      offsetX = detailsState.location.shotIcon.x - detailsState.location.targetIcon.x;
      offsetY = detailsState.location.shotIcon.y - detailsState.location.targetIcon.y;
      shotRange = (Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2))).toFixed(1);
    }

    // calculate angle
    let angle = 0;
    if (shotRange > 0) {
      if (offsetY < 0 && offsetX < 0)
        angle = (Math.asin(Math.abs(offsetY) / shotRange) / Math.PI * 180).toFixed(1);
      if (offsetY < 0 && offsetX > 0)
        angle = (Math.asin(Math.abs(offsetX) / shotRange) / Math.PI * 180 + 90).toFixed(1);
      if (offsetY > 0 && offsetX > 0)
        angle = (Math.asin(Math.abs(offsetY) / shotRange) / Math.PI * 180 + 180).toFixed(1);
      if (offsetY > 0 && offsetX < 0)
        angle = (Math.asin(Math.abs(offsetX) / shotRange) / Math.PI * 180 + 270).toFixed(1);
    }

    setDetailsState((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        shotRange: Number(shotRange),
        angle: Number(angle),
      },
    }));
  }, [detailsState && detailsState.location.shotIcon, detailsState && detailsState.location.targetIcon]);

  const handleChange = useCallback((event) => {
    const target = event.target;
    const location = detailsState.location;
    switch (target.name) {
      case 'side':
        setDetailsState((prevState) => ({ ...prevState, side: target.value }));
        break;
      case 'title':
        setDetailsState((prevState) => ({ ...prevState, title: target.value }));
        break;
      case 'icon':
        setDetailsState((prevState) => ({ ...prevState, icon: target.value }));
        break;
      case 'description':
        setDetailsState((prevState) => ({
          ...prevState,
          description: target.value,
        }));
        break;
      case 'map':
        setDetailsState((prevState) => ({ ...prevState, map: target.value }));
        break;
      case 'range':
        setDetailsState((prevState) => ({ ...prevState, range: target.value }));
        break;
      case 'shotIconY':
        location.shotIcon.y = target.value;
        setDetailsState((prevState) => ({ ...prevState, location }));
        break;
      case 'shotIconX':
        location.shotIcon.x = target.value;
        setDetailsState((prevState) => ({ ...prevState, location }));
        break;
      case 'targetIconY':
        location.targetIcon.y = target.value;
        setDetailsState((prevState) => ({ ...prevState, location }));
        break;
      case 'targetIconX':
        location.targetIcon.x = target.value;
        setDetailsState((prevState) => ({ ...prevState, location }));
        break;
      case 'clipX':
        if (clipSize === 'small') {
          const tmpSmallClilp = {...smallClip};
          tmpSmallClilp.x = target.value;
          setSmallClip(tmpSmallClilp);
        } else {
          const tmpBigClilp = {...bigClip};
          tmpBigClilp.x = target.value;
          setBigClip(tmpBigClilp);
        }
        break;
      case 'clipY':
        if (clipSize === 'small') {
          const tmpSmallClilp = {...smallClip};
          tmpSmallClilp.y = target.value;
          setSmallClip(tmpSmallClilp);
        } else {
          const tmpBigClilp = {...bigClip};
          tmpBigClilp.y = target.value;
          setBigClip(tmpBigClilp);
        }
        break;
      case 'clipWidth':
        if (clipSize === 'small') {
          const tmpSmallClilp = {...smallClip};
          tmpSmallClilp.width = target.value;
          tmpSmallClilp.height = Number((target.value * smallClipRate).toFixed(1));
          setSmallClip(tmpSmallClilp);
        } else {
          const tmpBigClilp = {...bigClip};
          tmpBigClilp.width = target.value;
          tmpBigClilp.height = Number((target.value * bigClipRate).toFixed(1));
          setBigClip(tmpBigClilp);
        }
        break;
      case 'clipHeight':
        if (clipSize === 'small') {
          const tmpSmallClilp = {...smallClip};
          tmpSmallClilp.height = target.value;
          tmpSmallClilp.width = Number((target.value / smallClipRate).toFixed(1));
          setSmallClip(tmpSmallClilp);
        } else {
          const tmpBigClilp = {...bigClip};
          tmpBigClilp.height = target.value;
          tmpBigClilp.width = Number((target.value / bigClipRate).toFixed(1));
          setBigClip(tmpBigClilp);
        }
        break;
      default:
        break;
    }
  }, [detailsState && detailsState.location, clipSize, smallClip, bigClip]);

  const handleMapClick = useCallback(
    (e) => {
      const rect = e.target.getBoundingClientRect();
      const clickX = e.clientX - rect.left; // x position within the element.
      const clickY = e.clientY - rect.top;
      const mapWidth = rect.width;
      const mapHeight = rect.height;

      const percentX = roundToTwo((clickX / mapWidth) * 100);
      const percentY = roundToTwo((clickY / mapHeight) * 100);

      setDetailsState((prevState) => ({
        ...prevState,
        location: isShotSpot ? {
          ...prevState.location,
          shotIcon: {
            x: percentX,
            y: percentY,
          },
        } : {
          ...prevState.location,
          targetIcon: {
            x: percentX,
            y: percentY,
          },
        },
      }));

      console.log(detailsState, '!!');
    },
    [isShotSpot, detailsState]
  );

  // handle move clip thumbnail box
  const handleMouseUpClip = event => {
    setIsMovingClipBox(false);
  };

  const handleMouseDownClip = useCallback(
    (event) => {
      setIsMovingClipBox(true);
      setMoveStartPos({
        x: event.clientX,
        y: event.clientY,
      });
    },
    []
  );

  const handleMouseMoveClip = useCallback(
    (event) => {
      if (isMovingClipBox) {
        const offsetX = (event.clientX - moveStartPos.x) / mapElem.current.offsetWidth * 100;
        const offsetY = (event.clientY - moveStartPos.y) / mapElem.current.offsetHeight * 100;

        const { newX, newY } = calcNewClipPox(clipSize === 'small' ? smallClip : bigClip, offsetX, offsetY, event.clientX, event.clientY);

        if (clipSize === 'small') {          
          setSmallClip({
            ...smallClip,
            x: newX,
            y: newY,
          });
        } else {
          setBigClip({
            ...bigClip,
            x: newX,
            y: newY,
          });
        }
      }
    },
    [isMovingClipBox, clipSize, smallClip, bigClip]
  );

  // calcution functions
  const calcNewClipPox = (curClip, offsetX, offsetY, clientX, clientY) => {
    let newX = Number((curClip.x + offsetX).toFixed(2));
    if (newX < 0) newX = 0;
    if (newX > 100 - curClip.width) newX = 100 - curClip.width;
    
    let newY = Number((curClip.y + offsetY).toFixed(2));
    if (newY < 0) newY = 0;
    if (newY > 100 - curClip.height) newY = 100 - curClip.height;

    setMoveStartPos({
      x: newX === curClip.x ? moveStartPos.x : clientX,
      y: newY === curClip.y ? moveStartPos.y : clientY,
    });

    return { newX, newY };
  };

  if(!detailsState) return null;

  const {
    side,
    title,
    video,
    description,
    location,
    map,
    range,
  } = detailsState;

  const selectedMap = MapsList.find((listMap) => listMap.key === map);
  const selectedMapImg = selectedMap && selectedMap.images.layout;

  const handleVideoChange = async (event) => {
    console.log(event.target.files[0].name);
    setVideoFile(event.target.files[0]);
  };

  const handleVideoUpload = async (event) => {
    event.preventDefault();

    if (!videoFile) {
      console.log('No file found!');
      return;
    }
    setUploading(true);
    const fileNameArray = videoFile.name.split('.');
    if (fileNameArray.length !== 2) {
      console.log('Invalid File Name');
      return;
    }

    const formData = new FormData();
    formData.append('video', videoFile);
    formData.append(
      'authTokenClient',
      'asdbjashuadgjasdjkahasjdbajhsdvasjdbkasdkjahasjkdnajhdvahd'
    );

    const response = await fetch('https://data.iesdev.com/api/video', {
      method: 'POST',
      body: formData,
    });

    const result = await response.json();
    if (result.err) {
      console.log(result.err);
      alert('Video upload and update failed!');
      return;
    } else {
      setDetailsState((prevState) => ({
        ...prevState,
        video: `${cdnPrefix}${result.data.Key}`,
      }));
      alert('Video has been successfully loaded!');
    }
    setUploading(false);
  };

  return (
    <form>
      <div className="form-group">
        <h3 className="font-weight-bold">Basic Details</h3>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Title</label>
            <input
              className="form-control"
              placeholder="Title"
              name="title"
              type="text"
              value={title}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Map</label>
            {MapsList ? (
              <select
                className="form-control"
                name="map"
                onChange={handleChange}
                value={map}
              >
                <option value="">--Choose a map--</option>
                {MapsList.map((map) => (
                  <option key={map.key} value={map.key}>
                    {map.name}
                  </option>
                ))}
              </select>
            ) : (
                <input
                  className="form-control"
                  placeholder="Map"
                  name="map"
                  type="text"
                  value={map}
                  onChange={handleChange}
                />
              )}
          </div>
          <div className="col">
            <label className="font-weight-bold">Side</label>
            <select
              className="form-control"
              name="side"
              onChange={handleChange}
              value={side}
            >
              {SIDES.map((side) => (
                <option key={side.key} value={side.key}>
                  {side.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <label className="font-weight-bold">Range</label>
            <select
              className="form-control"
              name="range"
              onChange={handleChange}
              value={range}
            >
              {RANGES.map((side) => (
                <option key={side.key} value={side.key}>
                  {side.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Description</label>
            <textarea
              className="form-control"
              name="description"
              value={description}
              onChange={handleChange}
              rows="4"
              cols="40"
            />
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div>
        <h3 className="font-weight-bold">Video</h3>
        {video && (
          <div className="row">
            <div className="col">
              <video src={video} controls width="450" />
              <input
                type="text"
                className="form-control"
                value={video}
                disabled
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <input
              className="form-control-file"
              name="videoFile"
              type="file"
              onChange={handleVideoChange}
            />
            <br />
            {uploading ? (
              <div>
                <img src="https://i.giphy.com/sSgvbe1m3n93G.gif" width="75" />
                <div>Video uploading... (this will take some time)</div>
              </div>
            ) : (
                <button className="btn btn-warning" onClick={handleVideoUpload}>
                  Upload Video
                </button>
              )}
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="form-group">
        <h3 className="font-weight-bold">Map Location</h3>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Shot Spot X Axis %</label>
            <input
              ref={shotXPosInput}
              className="form-control"
              placeholder="Icon-X"
              name="shotIconX"
              type="text"
              value={location.shotIcon.x}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Shot Spot Y Axis %</label>
            <input
              ref={shotYPosInput}
              className="form-control"
              placeholder="Icon-Y"
              name="shotIconY"
              type="text"
              value={location.shotIcon.y}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Target Spot Location Information */}
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Target Spot X Axis %</label>
            <input
              ref={targetXPosInput}
              className="form-control"
              placeholder="Icon-X"
              name="targetIconX"
              type="text"
              value={location.targetIcon.x}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Target Spot Y Axis %</label>
            <input
              ref={targetYPosInput}
              className="form-control"
              placeholder="Icon-Y"
              name="targetIconY"
              type="text"
              value={location.targetIcon.y}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Thumbnail Clip Size Information */}
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">X Axis %</label>
            <input
              ref={clipXPosInput}
              className="form-control"
              name="clipX"
              type="number"
              min={0}
              max={100}
              step={1}
              value={clipSize === 'small' ? smallClip.x : bigClip.x}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Y Axis %</label>
            <input
              ref={clipYPosInput}
              className="form-control"
              name="clipY"
              type="number"
              min={0}
              max={100}
              step={1}
              value={clipSize === 'small' ? smallClip.y : bigClip.y}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Width Axis %</label>
            <input
              ref={clipWidthInput}
              className="form-control"
              name="clipWidth"
              type="number"
              min={0}
              max={100}
              step={1}
              value={clipSize === 'small' ? smallClip.width : bigClip.width}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Height Axis %</label>
            <input
              ref={clipHeightInput}
              className="form-control"
              name="clipHeight"
              type="number"
              min={0}
              max={100}
              step={1}
              value={clipSize === 'small' ? smallClip.height : bigClip.height}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          {selectedMapImg && (
            <div>
              <br />
              <label className="font-weight-bold">
                Click on the map to set the X/Y position
              </label>

              <div className="row">
                {/* map frame */}
                <div className="map-frame-outer">
                  <div className="map-frame">
                    <img
                      ref={mapElem}
                      src={selectedMapImg}
                      onClick={handleMapClick}
                      className="map-image"
                    />
                    {location.shotRange !== 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          width: `${location.shotRange}%`,
                          height: 2,
                          top: `${location.shotIcon.y}%`,
                          left: `${location.shotIcon.x}%`,
                          background: 'green',
                          transform: `rotate(${location.angle}deg)`,
                          transformOrigin: 'left',
                        }}
                      />
                    )}
                    {location.shotIcon.x !== 0 && (
                      <div
                        className="dot red"
                        style={{
                          top: `${location.shotIcon.y}%`,
                          left: `${location.shotIcon.x}%`,
                        }}
                      />
                    )}
                    {location.targetIcon.x !== 0 && (
                      <div
                        className="dot blue"
                        style={{
                          top: `${location.targetIcon.y}%`,
                          left: `${location.targetIcon.x}%`,
                        }}
                      />
                    )}
                    {clipSize !== 'none' && (
                      <div
                        className="clip"
                        style={{
                          top: `${clipSize === 'small' ? smallClip.y : bigClip.y}%`,
                          left: `${clipSize === 'small' ? smallClip.x : bigClip.x}%`,
                          width: `${clipSize === 'small' ? smallClip.width : bigClip.width}%`,
                          height: `${clipSize === 'small' ? smallClip.height : bigClip.height}%`,
                        }}
                        ref={clipElem}
                        onMouseDown={handleMouseDownClip}
                        onMouseUp={handleMouseUpClip}
                        onMouseMove={handleMouseMoveClip}
                      />
                    )}
                  </div>
                </div>

                {/* control shot and target spots, and clip rectangles  */}
                <div className="control-map-container">
                  {/* form for switching spot to shot or to target */}
                  <div className="switch-control-form">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"  
                          value={true}
                          checked={isShotSpot}
                          onChange={() => setIsShotSpot(true)}
                        />
                        Shot Spot (<span className="color-red">red</span>)
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value={false}
                          checked={!isShotSpot}
                          onChange={() => setIsShotSpot(false)}
                        />
                        Target Spot (<span className="color-blue">blue</span>)
                      </label>
                    </div>
                  </div>

                  {/* switch clip size to small or big */}
                  <div className="switch-control-form">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value={'small'}
                          checked={clipSize === 'small'}
                          onChange={() => setClipSize('small')}
                        />
                        Small Thumbnail
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value={'big'}
                          checked={clipSize === 'big'}
                          onChange={() => setClipSize('big')}
                        />
                        Big Thumbnail
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        {wrongText && <p className="wrong-text">{wrongText}</p>}
        {uploading ? (
          'Please wait while video upload completes'
        ) : (
            <button 
              onClick={(e) => {
                e.preventDefault();
                // validate detailsState
                if (!detailsState.title) {
                  setWrongText('Please input the title!');
                  return;
                } else if (!detailsState.map) {
                  setWrongText('Please select the map type!');
                  return;
                } else if (!detailsState.description) {
                  setWrongText('Please input the description!');
                  return;
                } else if (!detailsState.video) {
                  setWrongText('Please Upload Video!');
                  return;
                } else if (detailsState.location.shotIcon.x === 0 || detailsState.location.shotIcon.y === 0) {
                  setWrongText('Please pick up the shot spot!');
                  return;
                } else if (detailsState.location.targetIcon.x === 0 || detailsState.location.targetIcon.y === 0) {
                  setWrongText('Please pick up the target spot!');
                  return;
                } else if (clipSize === 'none') {
                  setWrongText('Please select the clip box!');
                  return;
                } else {
                  setWrongText('');
                }

                // upload new headshot or updated headshot
                const headshot = {
                  ...detailsState,
                  updatedAt: new Date().getTime(),
                  location: {
                    ...detailsState.location,
                    clipSize: clipSize,
                    clip: clipSize === 'small' ? smallClip : bigClip,
                  }
                } 
                const index = ListOfHeadshots.findIndex(t => t.id === detailsState.id);
                dispatch(putHeadshot({headshot: headshot, ListOfHeadshots, index}))
              }}
              className="btn btn-lg btn-primary btn-block"
            >Submit</button>
          )}
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  MapsList: state.product.valorant.maps,
  ListOfHeadshots: state.product.valorant.headshots || []
})

export default connect(mapStateToProps)(HeadshotDetails);
