import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  fetchAndUpdateGuides,
  fetchAndUpdateMaps,
  fetchAndUpdateAgents,
  putGuide,
} from 'common/reducers/product/valorant/actions';
import { connect } from 'react-redux';

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

function getYouTubeID(url) {
  if (!url) return null;
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

const SIDES = [
  {
    key: 'attacking',
    name: 'Attacking',
  },
  {
    key: 'defending',
    name: 'Defending',
  },
  {
    key: 'both',
    name: 'Both',
  },
];

const DIFFICULTIES = [
  {
    key: 'easy',
    name: 'Easy',
  },
  {
    key: 'medium',
    name: 'Medium',
  },
  {
    key: 'pro',
    name: 'Pro',
  },
];

const defaultState = {
  agent: '',
  side: '',
  title: '',
  icon: '',
  difficulty: '',
  type: 'video',
  ytvideo: '',
  video: '',
  sound: false,
  thumbnail: '',
  description: '',
  map: '',
  image: '',
  location: {
    icon: {
      y: 0,
      x: 0,
    },
  },
};

const cdnPrefix = 'https://blitz-cdn-videos.blitz.gg/';

const TipDetails = (props) => {
  const { ListOfTips, AgentsList, MapsList, dispatch } = props;
  const [uploading, setUploading] = useState(false);
  const [tipState, setTipState] = useState();
  const [vidType, setVidType] = useState('yt');
  const mapElem = useRef(null);
  const xPosInput = useRef(null);
  const yPosInput = useRef(null);
  const lastPinned = props.location.state.lastPinned
  useEffect(() => {
    if (location) {
      xPosInput.current.value = location.icon.x;
      yPosInput.current.value = location.icon.y;
    }
  }, [location]);

  const [videoFile, setVideoFile] = useState(null);

  const id = props.match.params.id;
  useEffect(() => {
    if (ListOfTips && ListOfTips.length && !tipState) {
      setTipState(
        ListOfTips.find((t) => t.id === id) || { ...defaultState, id }
      );
    }
  }, [id, ListOfTips, tipState]);
  useEffect(() => {
    Promise.all([
      dispatch(fetchAndUpdateAgents()),
      dispatch(fetchAndUpdateGuides()),
      dispatch(fetchAndUpdateMaps()),
    ]);
  }, []);

  const handleChange = useCallback(
    (event) => {
      const target = event.target;
      const location = tipState.location;
      switch (target.name) {
        case 'agent':
          setTipState((prevState) => ({ ...prevState, agent: target.value }));
          break;
        case 'side':
          setTipState((prevState) => ({ ...prevState, side: target.value }));
          break;
        case 'title':
          setTipState((prevState) => ({ ...prevState, title: target.value }));
          break;
        case 'icon':
          setTipState((prevState) => ({ ...prevState, icon: target.value }));
          break;
        case 'pinned':
          setTipState((prevState) => ({ ...prevState, pinned: prevState.pinned  === 1 ? undefined : 1}));
          break;  
        case 'difficulty':
          setTipState((prevState) => ({
            ...prevState,
            difficulty: target.value,
          }));
          break;
        case 'description':
          setTipState((prevState) => ({
            ...prevState,
            description: target.value,
          }));
          break;
        case 'map':
          setTipState((prevState) => ({ ...prevState, map: target.value }));
          break;
        case 'iconY':
          location.icon.y = target.value;
          setTipState((prevState) => ({ ...prevState, location }));
          break;
        case 'iconX':
          location.icon.x = target.value;
          setTipState((prevState) => ({ ...prevState, location }));
          break;
        case 'ytvideo':
          setTipState((prevState) => ({
            ...prevState,
            ytVideoURL: target.value,
            ytVideoID: getYouTubeID(target.value),
          }));
          break;
        default:
          break;
      }
    },
    [tipState && tipState.location]
  );

  const handleMapClick = useCallback(
    (e) => {
      const rect = e.target.getBoundingClientRect();
      const clickX = e.clientX - rect.left; //x position within the element.
      const clickY = e.clientY - rect.top;
      const mapWidth = rect.width;
      const mapHeight = rect.height;

      const percentX = roundToTwo((clickX / mapWidth) * 100);
      const percentY = roundToTwo((clickY / mapHeight) * 100);

      setTipState((prevState) => ({
        ...prevState,
        location: {
          icon: {
            x: percentX,
            y: percentY,
          },
        },
      }));
    },
    [tipState]
  );

  if (!tipState) return null;

  const {
    agent,
    side,
    title,
    icon,
    difficulty,
    type,
    ytVideoID,
    ytVideoURL,
    video,
    pinned,
    sound,
    description,
    image,
    location,
    map,
  } = tipState;
  const selectedAgent = AgentsList.find((listAgent) => listAgent.key === agent);
  const agentAbilities = (selectedAgent && selectedAgent.abilities) || null;

  const selectedMap = MapsList.find((listMap) => listMap.key === map);
  const selectedMapImg = selectedMap && selectedMap.images.layout;

  const handleVideoChange = async (event) => {
    setVideoFile(event.target.files[0]);
  };

  const checkForDuplicateTipVideo = (fileName) => {
    fileName = fileName.toLowerCase();
    for (let tip of ListOfTips) {
      if (tip.id === props.match.params.id) continue;
      if (tip.video.toLowerCase().includes(`/${fileName}`)) return true;
    }
    return false;
  };

  const handleVideoUpload = async (event) => {
    event.preventDefault();
    if (!videoFile) {
      console.log('No file found!');
      return;
    }

    const duplicateFound = checkForDuplicateTipVideo(videoFile.name);
    if (duplicateFound) {
      alert('Video Tip Already Exists!');
      return;
    }

    setUploading(true);
    const fileNameArray = videoFile.name.split('.');
    if (fileNameArray.length !== 2) {
      console.log('Invalid File Name');
      return;
    }

    const formData = new FormData();
    formData.append('video', videoFile);
    formData.append(
      'authTokenClient',
      'asdbjashuadgjasdjkahasjdbajhsdvasjdbkasdkjahasjkdnajhdvahd'
    );

    const response = await fetch('https://data.iesdev.com/api/video', {
      method: 'POST',
      body: formData,
    });

    const result = await response.json();
    if (result.err) {
      console.log(result.err);
      alert('Video upload and update failed!');
      return;
    } else {
      setTipState((prevState) => ({
        ...prevState,
        video: `${cdnPrefix}${result.data.Key}`,
      }));
      alert('Video has been successfully loaded!');
    }
    setUploading(false);
  };

  return (
    <form>
      <div className="form-group">
        <h3 className="font-weight-bold">Basic Details</h3>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Title</label>
            <input
              className="form-control"
              placeholder="Title"
              name="title"
              type="text"
              value={title}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Agent</label>
            {AgentsList ? (
              <select
                className="form-control"
                name="agent"
                onChange={handleChange}
                value={agent}
              >
                <option value="">--Choose an agent--</option>
                {AgentsList.map((agent) => (
                  <option key={agent.key} value={agent.key}>
                    {agent.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="form-control"
                placeholder="Agent"
                name="agent"
                type="text"
                value={agent}
                onChange={handleChange}
              />
            )}
          </div>
          <div className="col">
            <label className="font-weight-bold">Ability</label>
            {agentAbilities && agentAbilities.length && (
              <select
                className="form-control"
                name="icon"
                onChange={handleChange}
                value={icon}
              >
                <option value="">--Choose an ability--</option>
                {agentAbilities.map((ability) => (
                  <option key={ability.key} value={ability.key}>
                    {ability.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="col">
            <label className="font-weight-bold">Map</label>
            {MapsList ? (
              <select
                className="form-control"
                name="map"
                onChange={handleChange}
                value={map}
              >
                <option value="">--Choose a map--</option>
                {MapsList.map((map) => (
                  <option key={map.key} value={map.key}>
                    {map.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="form-control"
                placeholder="Map"
                name="map"
                type="text"
                value={map}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <div className="row">
          {/* <div className="col">
            <input
              className="form-control"
              placeholder="Type"
              name="type"
              type="text"
              value={type}
              onChange={handleChange}
            />
          </div> */}
          <div className="col">
            <label className="font-weight-bold">Side</label>
            <select
              className="form-control"
              name="side"
              onChange={handleChange}
              value={side}
            >
              <option value="">--Choose a side--</option>
              {SIDES.map((side) => (
                <option key={side.key} value={side.key}>
                  {side.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <label className="font-weight-bold">Difficulty</label>
            <select
              className="form-control"
              name="difficulty"
              onChange={handleChange}
              value={difficulty}
            >
              <option value="">--Choose a difficulty--</option>
              {DIFFICULTIES.map((DIFFICULTIES) => (
                <option key={DIFFICULTIES.key} value={DIFFICULTIES.key}>
                  {DIFFICULTIES.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Description</label>
            <textarea
              className="form-control"
              name="description"
              value={description}
              onChange={handleChange}
              rows="4"
              cols="40"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Pinned</label>
           <div className={'radio-select'}>
              <div className='radio-select--btn'>
                <input
                  type="radio"
                  name="pinned"
                  checked={pinned === 1}
                  onClick={handleChange}
                  defaultChecked={pinned === 1}
                />
              </div>
             </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div>
        <h3 className="font-weight-bold">Video</h3>
        <div className="row">
          <div className="col">
            {vidType === 'yt' ? (
              <div>
                <label className="font-weight-bold">YouTube URL</label>
                <input
                  className="form-control"
                  placeholder="YouTube Video ID"
                  name="ytvideo"
                  type="text"
                  value={ytVideoURL}
                  onChange={handleChange}
                />
                {ytVideoID && ytVideoID.length > 4 && (
                  <iframe
                    className="ytplayer"
                    type="text/html"
                    width="400"
                    height="225"
                    src={`https://www.youtube.com/embed/${ytVideoID}?autoplay=0&loop=1&playlist=${ytVideoID}&modestbranding=1`}
                    frameborder="0"
                  ></iframe>
                )}
              </div>
            ) : (
              <div>
                <label className="font-weight-bold">CDN URL</label>

                <input
                  className="form-control-file"
                  name="videoFile"
                  type="file"
                  onChange={handleVideoChange}
                />
                <br />
                {video && (
                  <div>
                    <p>{video}</p>
                    <video
                      src={video}
                      controls
                      width="400"
                      className="ytplayer"
                    />
                  </div>
                )}
                {uploading ? (
                  <div>
                    <img
                      src="https://i.giphy.com/sSgvbe1m3n93G.gif"
                      width="75"
                    />
                    <div>Video uploading... (this will take some time)</div>
                  </div>
                ) : (
                  <button
                    className="btn btn-warning"
                    onClick={handleVideoUpload}
                  >
                    Upload Video
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="col-6">
            <label className="font-weight-bold">Video Type</label>
            <div className="radio-select">
              <div
                className={`radio-select--btn ${
                  vidType === 'yt' ? 'checked' : ''
                }`}
              >
                <input
                  type="radio"
                  id="yt"
                  name="video-type"
                  value="yt"
                  onClick={() => setVidType('yt')}
                  defaultChecked={vidType === 'yt'}
                />
                <label htmlFor="yt" onClick={() => setVidType('yt')}>
                  YouTube Video{' '}
                  {ytVideoID && <span>✔️</span>}
                </label>
              </div>
              <div
                className={`radio-select--btn ${
                  vidType === 'upload' ? 'checked' : ''
                }`}
              >
                <input
                  type="radio"
                  id="upload"
                  name="video-type"
                  value="upload"
                  onClick={() => setVidType('upload')}
                  defaultChecked={vidType === 'upload'}
                />
                <label htmlFor="upload" onClick={() => setVidType('upload')}>
                  Upload Video{' '}
                  { video && <span>✔️</span>}
                </label>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <label className='font-weight-bold'>Sound</label>
            <div className={'radio-select'}>
              <div className='radio-select--btn'>
                <input
                  type='radio'
                  id='sound'
                  name='sound'
                  value='sound'
                  checked={sound}
                  onClick={() => setTipState((prevState) => ({ ...prevState, sound: !sound }))}
                  onChange={() => {}}
                />
                <label htmlFor="sound">
                  {sound ? <>Video has sound</> : <>Video has no voice over</>}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="form-group">
        <h3 className="font-weight-bold">Map Location</h3>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">X Axis %</label>
            <input
              ref={xPosInput}
              className="form-control"
              placeholder="Icon-X"
              name="iconX"
              type="text"
              value={location.icon.x}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Y Axis %</label>
            <input
              ref={yPosInput}
              className="form-control"
              placeholder="Icon-Y"
              name="iconY"
              type="text"
              value={location.icon.y}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          {selectedMapImg && (
            <div>
              <br />
              <label className="font-weight-bold">
                Click on the map to set the X/Y position
              </label>
              <div className="map-frame-outer">
                <div className="map-frame">
                  <img
                    ref={mapElem}
                    src={selectedMapImg}
                    onClick={handleMapClick}
                    className="map-image"
                  />
                  {location.icon.x !== 0 && (
                    <div
                      className="dot"
                      style={{
                        top: `${location.icon.y}%`,
                        left: `${location.icon.x}%`,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        {uploading ? (
          'Please wait while video upload completes'
        ) : (
          <button
            onClick={(e) => {
              const index = ListOfTips.findIndex((t) => t.id === tipState.id);
              e.preventDefault();
              dispatch(putGuide({tip: tipState, ListOfTips, index, lastPinned   }));
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            Submit
          </button>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  AgentsList: state.product.valorant.agents,
  MapsList: state.product.valorant.maps,
  ListOfTips: state.product.valorant.guides,
});

export default connect(mapStateToProps)(TipDetails);
