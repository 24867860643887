import React, { Component, useEffect, useState } from 'react';
import Insight from './Insight';
import Ability from './Ability';
import Video from './Video';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { putAgent } from 'common/reducers/product/valorant/actions';

const defaultAgent = {
  key: '',
  name: '',
  Class: '',
  images: {
    bg: '',
    cutout: '',
    headshot: '',
  },
  insights: [],
  abilities: [],
  videos: [],
};

const AgentDetails = (props) => {
  const [agent, setAgent] = useState();
  const agentKey = props.match.params.agent;
  const { ListOfAgents } = props;

  useEffect(() => {
    if (ListOfAgents && !agent) {
      setAgent(ListOfAgents.find((a) => a.key === agentKey) || defaultAgent);
    }
  }, [ListOfAgents, agentKey]);

  const handleAddInsight = (event) => {
    event.preventDefault();
    const newInsight = { side: '', tip: '', extra: '' };
    const insights = agent.insights.concat(newInsight);
    setAgent((prevState) => ({ ...prevState, insights }));
  };

  const handleAddAbility = (event) => {
    event.preventDefault();
    const newAbility = {
      key: '',
      name: '',
      type: '',
      chargesCount: '',
      description: '',
      video: '',
      cost: '',
      keybind: '',
    };
    const abilities = agent.abilities.concat(newAbility);
    setAgent((prevState) => ({ ...prevState, abilities }));
  };

  const handleAddVideo = (event) => {
    event.preventDefault();
    const newVideo = { id: '', start: '', end: '' };
    const videos = agent.videos.concat(newVideo);
    setAgent((prevState) => ({ ...prevState, videos }));
  };

  const handleInsightChange = (newInsight, insightId) => {
    const insights = agent.insights.map((insight, index) =>
      index === insightId ? newInsight : insight
    );
    setAgent((prevState) => ({ ...prevState, insights }));
  };

  const handleAbilityChange = (newAbility, abilityId) => {
    const abilities = agent.abilities.map((ability, index) =>
      index === abilityId ? newAbility : ability
    );
    setAgent((prevState) => ({ ...prevState, abilities }));
  };

  const handleVideoChange = (newVideo, videoId) => {
    const videos = agent.videos.map((video, index) =>
      index === videoId ? newVideo : video
    );
    setAgent((prevState) => ({ ...prevState, videos }));
  };

  const handleChange = (event) => {
    const target = event.target;
    const images = agent.images;
    switch (target.name) {
      case 'agentName':
        setAgent((prevState) => ({
          ...prevState,
          name: target.value,
          key: target.value.toLowerCase(),
        }));
        break;
      case 'agentClass':
        setAgent((prevState) => ({ ...prevState, Class: target.value }));
        break;
      case 'bg':
        images.bg = target.value;
        setAgent((prevState) => ({ ...prevState, images }));
        break;
      case 'cutout':
        images.cutout = target.value;
        setAgent((prevState) => ({ ...prevState, images }));
        break;
      case 'headshot':
        images.headshot = target.value;
        setAgent((prevState) => ({ ...prevState, images }));
        break;
      case 'key':
        setAgent((prevState) => ({ ...prevState, key: target.value }));
        break;  
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let agentFound = false, agentIndex=undefined;
    ListOfAgents.forEach((a, index) => {
      if (a.key === agent.key) {
        agentFound = true;
        agentIndex = index
      }
    });
    if (agentKey === 'new-agent' && agentFound) {
      alert('Agent already Exists!');
      return;
    }
    props.dispatch(putAgent({agent: agent, ListOfAgents, index: agentIndex }));
  }
  if (!agent) return null;

  const { key, name, Class, images, insights, abilities, videos } = agent;

  return (
    <form>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Name</label>
            <input
              className="form-control"
              name="agentName"
              type="text"
              value={name}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label className="font-weight-bold">Class</label>
            <input
              className="form-control"
              name="agentClass"
              type="text"
              value={Class}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Key</label>
            <input
              className="form-control"
              name="key"
              type="text"
              value={key}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="form-group">
        <h3 className="font-weight-bold">Images</h3>
        <div className="row">
          <div className="col">
            <label className="font-weight-bold">Blurred BG</label>
            <input
              className="form-control"
              placeholder="Image BG"
              name="bg"
              type="text"
              value={images.bg}
              onChange={handleChange}
            />
            {images.bg && <img src={images.bg} width="150" />}
          </div>
          <div className="col">
            <label className="font-weight-bold">Cutout</label>
            <input
              className="form-control"
              placeholder="Image Cutout"
              name="cutout"
              type="text"
              value={images.cutout}
              onChange={handleChange}
            />
            {images.cutout && <img src={images.cutout} width="150" />}
          </div>
          <div className="col">
            <label className="font-weight-bold">Headshot</label>
            <input
              className="form-control"
              placeholder="Image Headshot"
              name="headshot"
              type="text"
              value={images.headshot}
              onChange={handleChange}
            />
            {images.headshot && <img src={images.headshot} width="100" />}
          </div>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="form-group">
        <h3 className="font-weight-bold">Insights</h3>
        {insights.map((insight, index) => (
          <React.Fragment key={index}>
            <Insight
              key={index}
              insightId={index}
              insight={insight}
              handleInsightChange={handleInsightChange}
            />
            <hr />
          </React.Fragment>
        ))}
        <button className="btn btn-sm btn-dark" onClick={handleAddInsight}>
          Add New Insight
        </button>
      </div>
      <br />
      <hr />
      <br />
      <div className="form-group">
        <h3 className="font-weight-bold">Abilities</h3>
        {abilities.map((ability, index) => (
          <div className="agent-ability" key={index}>
            <Ability
              key={index}
              abilityId={index}
              ability={ability}
              handleAbilityChange={handleAbilityChange}
            />
            <hr />
          </div>
        ))}
        <button className="btn btn-sm btn-dark" onClick={handleAddAbility}>
          Add New Ability
        </button>
      </div>
      <br />
      <br />
      <br />
      <div className="form-group">
        <h3 className="font-weight-bold">Videos</h3>
        {videos.map((video, index) => (
          <React.Fragment key={index}>
            <Video
              key={index}
              videoId={index}
              video={video}
              handleVideoChange={handleVideoChange}
            />
            <hr />
          </React.Fragment>
        ))}
        <button className="btn btn-dark btn-sm" onClick={handleAddVideo}>
          Add New Video
        </button>
        <br />
      </div>
      <div className="form-group">
        <button
          onClick={handleSubmit}
          className="btn btn-lg btn-primary btn-block"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  ListOfAgents: state.product.valorant.agents,
});

export default connect(mapStateToProps)(AgentDetails);
