import axios from 'axios';
import * as GraphQLTemplates from './GraphQLTemplates';

const ddragonURL = 'https://ddragon.leagueoflegends.com';
const URL = 'https://league-champion-aggregate.iesdev.com';
const instance = axios.create({ timeout: 10000, baseURL: URL });

instance.interceptors.request.use(
  (i_config) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(
        `\n(${i_config.method.toUpperCase()}) ${i_config.url} ${
          i_config.params ? JSON.stringify(i_config.params) : JSON.stringify(i_config.data)
        }`
      );
    }
    return i_config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class API {
  setToken(token) {
    console.log(token);
    instance.defaults.headers.common['Authorization'] = `Token ${token}`;
  }
  postLogin(params) {
    return new Promise((resolve) => {
      fetch(`https://riot.iesdev.com/api/users/login`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((data) => {
        resolve(data.json());
      });
    });
  }
  getStatsOverview() {
    return instance.get('/api/tracking/app/overall');
  }
  getStatsFilterable(params) {
    return instance.get('/api/tracking/app/filterable_stats', { params });
  }
  getStatsUsers(params) {
    return instance.get('/api/tracking/app/users/', { params });
  }
  deleteChampionTag(params) {
    return instance.delete(`/api/champion_tags`, params);
  }
  getChampionTag(id) {
    return instance.post(`/graphql`, { query: GraphQLTemplates.Champion, variables: { id } });
  }
  getChampionTags() {
    return instance.post(`/graphql`, { query: GraphQLTemplates.Champions });
  }
  postChampionTag(params) {
    return instance.post(`/api/champion_tags`, params);
  }
  newChampionTips(params) {
    return instance.post('/api/championtips', params);
  }
  getChampionTips(params) {
    return instance.get('/api/championtips', { params });
  }
  getChampionTip(id, params) {
    return instance.get(`/api/championtips/${id}`, { params });
  }
  patchChampionTip(id, params) {
    return instance.patch(`/api/championtips/${id}`, params);
  }
  getCurrentPatch() {
    return new Promise((resolve) => {
      fetch(`${URL}/api/patches/current`).then((data) => {
        resolve(data.json());
      });
    });
  }
  getPatch() {
    return new Promise((resolve) => {
      fetch(`${ddragonURL}/api/versions.json`).then((data) => {
        resolve(data.json());
      });
    });
  }
  getChampions(patch) {
    return new Promise((resolve) => {
      fetch(`${ddragonURL}/cdn/${patch}/data/en_US/championFull.json`).then((data) => {
        resolve(data.json());
      });
    });
  }
  getItems(patch) {
    return new Promise((resolve) => {
      fetch(`${ddragonURL}/cdn/${patch}/data/en_US/item.json`).then((data) => {
        resolve(data.json());
      });
    });
  }
  getSpells(patch) {
    return new Promise((resolve) => {
      fetch(`${ddragonURL}/cdn/${patch}/data/en_US/summoner.json`).then((data) => {
        resolve(data.json());
      });
    });
  }
  getRunesReforged(patch) {
    return new Promise((resolve) => {
      fetch(`${ddragonURL}/cdn/${patch}/data/en_US/runesReforged.json`).then((data) => {
        resolve(data.json());
      });
    });
  }
  getSummoner(region, name, params) {
    return instance.get(`/api/lolapi/${region}/accounts/name/${name}`, { params });
  }
  getSummonerBySummonerId(region, summonerId, params) {
    return instance.get(`/api/lolapi/${region}/accounts/summoner_id/${summonerId}`, { params });
  }
  getSummonerById(region, accountId, params) {
    return instance.get(`/api/lolapi/${region}/accounts/${accountId}`, { params });
  }
  getLeagues(region, summonerId, params) {
    return instance.get(`/api/lolapi/${region}/league_positions/${summonerId}`, {
      params,
      timeout: 60000,
    });
  }
  getProbuilds(params) {
    return instance.get(`/api/probuilds`, { params });
  }
  postProbuilds(params) {
    return instance.post(`/api/probuilds`, params);
  }
  patchProbuilds(id, params) {
    return instance.patch(`/api/probuilds/${id}`, params);
  }
  deleteProbuilds(id, params) {
    return instance.delete(`/api/probuilds/${id}`, params);
  }
  getTeams(params) {
    return instance.get(`/api/probuilds/teams`, { params });
  }
  postTeams(team) {
    return instance.post(`/api/probuilds/teams`, { team });
  }
  patchTeams(id, team) {
    return instance.put(`/api/probuilds/teams/${id}`, { team });
  }
  deleteTeams(id, params) {
    return instance.delete(`/api/probuilds/teams/${id}`, params);
  }
  getTier(params) {
    return instance.get(`/api/championtierlists/all`, { params });
  }
  patchTier(params) {
    return instance.patch(`/api/championtierlists/all`, params);
  }
  getMetaReportItems(params) {
    return instance.get(`/api/meta_report_items`, { params });
  }
  postMetaReportItems(params) {
    return instance.post(`/api/meta_report_items`, params);
  }
  patchMetaReportItem(id, params) {
    return instance.patch(`/api/meta_report_items/${id}`, params);
  }
  deleteMetaReportItem(id) {
    return instance.delete(`/api/meta_report_items/${id}`);
  }
  getMetaReportItem(id, params) {
    return instance.get(`/api/meta_report_items/${id}`, { params });
  }
  parseEntryFromUrl(params) {
    return instance.get('/api/entries/preview', { params });
  }
  getToast(game, params) {
    return instance.get(`/api/toast/${game}`, { params });
  }
  postToast(game, params) {
    return instance.post(`/api/toast/${game}`, params);
  }
  getLolToast(params) {
    return instance.get(`/api/beta/toast`, { params });
  }
  postLolToast(params) {
    return instance.post(`/api/beta/toast`, params);
  }
}

export default new API();
