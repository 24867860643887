import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { deleteWeapon, putWeapon, fetchAndUpdateWeapons } from 'common/reducers/product/valorant/actions';
import { connect } from 'react-redux';
import uuid from 'common/utils/uuid'

class ValorantWeapons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sidearms: [],
      SMGs: [],
      Shotguns: [],
      Rifles: [],
      Snipers: [],
      Heavy: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchAndUpdateWeapons());
    const conditions = (this.props.weapons.Sidearms.length === this.state.Sidearms.length) &&
      (this.props.weapons.SMGs.length === this.state.SMGs.length) &&
      (this.props.weapons.Shotguns.length === this.state.Shotguns.length) &&
      (this.props.weapons.Rifles.length === this.state.Rifles.length) &&
      (this.props.weapons.Snipers.length === this.state.Snipers.length) &&
      (this.props.weapons.Heavy.length === this.state.Heavy.length);

    if (!conditions)
      this.setState(this.props.weapons);

    if (this.props.location.state && this.props.location.state.weapon && this.state.Sidearms.length !== 0) {
      this.handleAddWeapon(this.props.location.state.weapon, this.props.location.state.id);
      this.props.history.replace({
        state: {},
      });
    }
  }

  componentDidUpdate() {
    const conditions = (this.props.weapons.Sidearms.length === this.state.Sidearms.length) &&
      (this.props.weapons.SMGs.length === this.state.SMGs.length) &&
      (this.props.weapons.Shotguns.length === this.state.Shotguns.length) &&
      (this.props.weapons.Rifles.length === this.state.Rifles.length) &&
      (this.props.weapons.Snipers.length === this.state.Snipers.length) &&
      (this.props.weapons.Heavy.length === this.state.Heavy.length);

    if (!conditions)
      this.setState(this.props.weapons);

    if (this.props.location.state && this.props.location.state.weapon && this.state.Sidearms.length !== 0) {
      this.handleAddWeapon(this.props.location.state.weapon, this.props.location.state.id);
      this.props.history.replace({
        state: {},
      });
    }
  }

  handleDeleteWeapon(target) {
    const newState = {
      Sidearms: [],
      SMGs: [],
      Shotguns: [],
      Rifles: [],
      Snipers: [],
      Heavy: [],
    };
    for (let wT in this.state) {
      const weaponType = wT[wT.length - 1] === 's' ? wT.slice(0, -1) : wT;
      if (weaponType !== target.type) {
        newState[`${wT}`] = this.state[`${wT}`];
      } else {
        newState[`${wT}`] = this.state[`${wT}`].filter(
          (weapon) => weapon.key !== target.key
        );
      }
    }
    this.setState(newState)
    this.props.dispatch(deleteWeapon({weapon:target, otherWeapons: newState}))
  }

  handleAddWeapon(newWeapon, id) {
    newWeapon.key = uuid();
    const prevState = this.state;
    const wT = newWeapon.type;
    const weaponType = wT[wT.length - 1] === 'y' ? wT : wT + 's';

    if (weaponType in prevState === false) {
      console.log("Invalid Weapon Type");
      return;
    }
    
    this.setState({
      ...prevState, 
      [weaponType]: [...prevState[weaponType], newWeapon]
    })
    this.props.dispatch(putWeapon({weapon:newWeapon, weaponType, id, weapons: prevState}))
  }

  render() {
    return (
      <React.Fragment>
        <div className="add-content-list-item-button">
          <Link
            to={{
              pathname: '/valorant/weapondetails',
            }}
          >
            <button className="btn btn-larg btn-primary btn-block">
              Add New Weapon
            </button>
          </Link>
        </div>
        <div>
          <table className="table table-borderless">
            <thead />
            {Object.entries(this.state).map((weaponsArray) => (
              <tbody key={weaponsArray[0]}>
                <tr>
                  <th>{weaponsArray[0]}</th>
                </tr>
                {weaponsArray[1].map((weapon, index) => (
                  <tr key={weapon.key}>
                    <td className="table-cell weapon-image">
                      <img src={weapon.images.model} />
                    </td>
                    <td className="table-cell">{weapon.name}</td>
                    <td className="table-cell">{weapon.cost}</td>
                    <td className="table-cell">
                      <Link
                        to={{
                          pathname: '/valorant/weapondetails',
                          state: { ...weapon, id: index },
                        }}
                      >
                        <i
                          className="fa fa-pencil edit-icon"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </td>
                    <td className="table-cell">
                      <i
                        onClick={() =>
                          this.handleDeleteWeapon(weapon)
                        }
                        className="fa fa-trash delete-icon"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
            <tfoot />
          </table>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  weapons: state.product.valorant.weapons
})

export default connect(mapStateToProps)(ValorantWeapons);