import React, { Component } from 'react';

class Insight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      side: '',
      tip: '',
      extra: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.insight);
  }

  handleChange(event) {
    const target = event.target;
    switch (target.name) {
      case 'side':
        this.setState({ side: target.value }, () =>
          this.props.handleInsightChange(this.state, this.props.insightId)
        );
        break;
      case 'tip':
        this.setState({ tip: target.value }, () =>
          this.props.handleInsightChange(this.state, this.props.insightId)
        );
        break;
      case 'extra':
        this.setState({ extra: target.value }, () =>
          this.props.handleInsightChange(this.state, this.props.insightId)
        );
        break;
    }
  }

  render() {
    const { side, tip, extra } = this.state;
    return (
      <div className="row">
        <div className="col">
          <label className="font-weight-bold">
            Side (General/attacking/defending)
          </label>
          <input
            className="form-control"
            placeholder="Side"
            name="side"
            type="text"
            value={side}
            onChange={this.handleChange}
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">Text</label>
          <textarea
            className="form-control"
            placeholder="text"
            name="tip"
            value={tip}
            onChange={this.handleChange}
            rows="4"
          />
        </div>
        <div className="col">
          <label className="font-weight-bold">Extra (optional)</label>
          <input
            className="form-control"
            placeholder="Extra"
            name="extra"
            type="text"
            value={extra}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default Insight;
