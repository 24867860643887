import updateResource from './helpers/update'

const method ="DELETE"
const action = "Delete"

const deleteResource = ({resource, type, others, updateMethod}) => {
  const data = undefined
  
  const updateFn = () => updateMethod(others)
  
  return updateResource({resource, type, data, action, updateFn, method})
}

export default deleteResource