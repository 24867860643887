import axios from 'axios';
import * as ActionTypes from './actionTypes';
import BlitzAPI from 'common/api/BlitzAPI';
import { load } from 'common/reducers/loading/actions';

export function initCurrentPatch() {
  return (dispatch) =>
    BlitzAPI.getCurrentPatch().then((response) => {
      const currentPatch = response.data;
      dispatch({
        type: ActionTypes.UPDATE_CURRENT_PATCH,
        currentPatch,
      });
      return currentPatch;
    });
}
export function initPatch() {
  return (dispatch) =>
    BlitzAPI.getPatch().then((response) => {
      const version = response[0];
      const patch = version.split('.').slice(0, 2).join('.');
      const patchObject = { version, patch };
      dispatch({
        type: ActionTypes.UPDATE_PATCH,
        patch: patchObject,
      });
      return patchObject;
    });
}
export function initDDragon(patch) {
  return (dispatch) => {
    const initChampions = BlitzAPI.getChampions(patch).then((response) => {
      dispatch({
        type: ActionTypes.UPDATE_CHAMPIONS,
        champions: response,
      });
    });
    const initRunes = BlitzAPI.getRunesReforged(patch).then((response) =>
      dispatch({
        type: ActionTypes.UPDATE_RUNES,
        runes: response,
      })
    );
    const initItems = BlitzAPI.getItems(patch).then((response) =>
      dispatch({
        type: ActionTypes.UPDATE_ITEMS,
        items: response,
      })
    );
    const initSpells = BlitzAPI.getSpells(patch).then((response) =>
      dispatch({
        type: ActionTypes.UPDATE_SPELLS,
        spells: response,
      })
    );
    return Promise.all([initChampions, initRunes, initItems, initSpells]);
  };
}

export function initChampionTips() {
  return (dispatch) => {
    const getChampionTips = BlitzAPI.getChampionTips().then((response) => {
      const tips = response.data.data;
      dispatch({
        type: ActionTypes.UPDATE_CHAMPION_TIPS,
        tips,
      });
      return tips;
    });
    return dispatch(load('init_champion_insights', getChampionTips));
  };
}

export function initProbuilds() {
  return (dispatch) => {
    const getProbuilds = BlitzAPI.getProbuilds().then((response) => {
      const probuilds = response.data.data;
      dispatch({
        type: ActionTypes.UPDATE_PROBUILDS,
        probuilds,
      });
      return probuilds;
    });
    return dispatch(load('init_probuilds', getProbuilds));
  };
}
export function updateProbuilds() {
  return (dispatch) => {
    const getProbuilds = BlitzAPI.getProbuilds().then((response) => {
      const probuilds = response.data.data;
      dispatch({
        type: ActionTypes.UPDATE_PROBUILDS,
        probuilds,
      });
      return probuilds;
    });
    return getProbuilds;
  };
}
export function initTeams() {
  return (dispatch) => {
    const getTeams = BlitzAPI.getTeams().then((response) => {
      const teams = response.data.data;
      dispatch({
        type: ActionTypes.UPDATE_TEAMS,
        teams,
      });
      return teams;
    });
    return dispatch(load('init_teams', getTeams));
  };
}
export function updateTeams() {
  return (dispatch) => {
    const getTeams = BlitzAPI.getTeams().then((response) => {
      const teams = response.data.data;
      dispatch({
        type: ActionTypes.UPDATE_TEAMS,
        teams,
      });
      return teams;
    });
    return getTeams;
  };
}
export function initTiers() {
  return (dispatch) => {
    const getTiers = BlitzAPI.getTier().then((response) => {
      const tiers = response.data.data;
      dispatch(updateTiers(tiers));
      return tiers;
    });
    return dispatch(load('init_tiers', getTiers));
  };
}
export function initMetaReport(params) {
  // console.log(params)
  return (dispatch) => {
    const getMetaReportItems = BlitzAPI.getMetaReportItems(params).then(
      (response) => {
        const metareport = response.data;
        dispatch(updateMetaReport(metareport));
        return metareport;
      }
    );
    return dispatch(load('init_meta_report', getMetaReportItems));
  };
}

export function updateTiers(tiers) {
  return {
    type: ActionTypes.UPDATE_TIERS,
    tiers,
  };
}
export function updateMetaReport(metareport) {
  return {
    type: ActionTypes.UPDATE_META_REPORT,
    metareport,
  };
}

export function updateProbuildsEditor(probuilds) {
  console.log(probuilds);
  return {
    type: ActionTypes.UPDATE_PROBUILDS_EDITOR,
    probuilds,
  };
}

export function updateTeamsEditor(teams) {
  return {
    type: ActionTypes.UPDATE_TEAMS_EDITOR,
    teams,
  };
}

export function initToast() {
  return (dispatch) => {
    const fetchlolToast = BlitzAPI.getLolToast().then((response) => {
      const toast = response.data;
      dispatch({
        type: ActionTypes.UPDATE_TOAST,
        toast,
      });
      return toast;
    });
    return dispatch(load('init_lol_toast', fetchlolToast));
  };
}
