import { DATA_BACKEND_URL, AUTH_TOKEN_CLIENT} from 'config'
import { push } from 'react-router-redux';
import notifier from 'common/utils/notifier'
import resourceId from './id'

const VALORANT = "valorant"
const UPDATE = "Update"

const updateResource = ({
  resource,
  game = VALORANT,
  type,
  method,
  data,
  updateFn,
  action = UPDATE
}) => (dispatch) => {
  const reqBody = {
    data: data,
    authTokenClient: AUTH_TOKEN_CLIENT,
  };

  return fetch(`${DATA_BACKEND_URL}/${game}/${type}/${resourceId(resource)}`, {
      method: method,
      body: JSON.stringify(reqBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      if (parseInt(res.status) >= 400) {
        notifier.error(`${action} failed (${res.status})`, res) 
        return
      }
      return res.json() 
    }).then((json) => {  
      const updated = json.data 
      dispatch(updateFn(updated));
      dispatch(push(`/${game}/${type}`))
    })
    .catch((error) => {
      console.log(error);
    });
}

export default updateResource