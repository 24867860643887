import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import { push } from 'react-router-redux';
import { logout } from 'common/reducers/app/actions';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';


function generateStyles(theme) {
  return {};
}

const Link = Radium(props => {
  const { gstyles, theme, styles, label, path, push, onClick, location } = props;
  const match = matchPath(location.pathname, {
    path,
    exact: true,
    strict: false,
  });
  const isActive = match ? true : false;
  return (
    <div
      onClick={() => push(path)}
      style={{ paddingTop: theme.spacing_4, paddingBottom: theme.spacing_4, cursor: 'pointer' }}>
      <div style={[isActive ? gstyles.p1_bold : gstyles.p1, { color: isActive ? theme.text() : theme.text(0.75) }]}>{label}</div>
    </div>
  );
});

const Header = Radium(props => {
  const { gstyles, theme, styles, label } = props;
  return (
    <div style={[gstyles.caption_bold, { color: theme.text(0.5) }, gstyles.top_1, gstyles.bottom_4]}>
      {label}
    </div>
  );
});
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    }
  }
  render() {
    const { gstyles, theme, styles, logout } = this.props;
    const { toggle } = this.state;
    return (
      <View style={{ width: toggle ? 150 : theme.sidebar, padding: 40, boxSizing: 'border-box' }}>
        <img
          src={'https://blitz-cdn.blitz.gg/blitz/guest-account/settings/New-Logo.png'}
          style={[{ height: 34, width: 34 }, gstyles.top_4]}
        />
        <div style={[gstyles.line, gstyles.top_1, gstyles.bottom_1]} />

        <Header label={'League of Legends'} {...this.props} />
        <Link path={'/lol/probuilds'} label={'Pro Builds'} {...this.props} />
        <Link path={'/lol/teams'} label={'Teams'} {...this.props} />
        <Header label={'Valorant'} {...this.props} />
        <Link path={'/valorant/agents'} label={'Agents'} {...this.props} />
        <Link path={'/valorant/weapons'} label={'Weapons'} {...this.props} />
        <Link path={'/valorant/maps'} label={'Maps'} {...this.props} />
        <Link path={'/valorant/tips'} label={'Tips'} {...this.props} />
        <Link path={'/valorant/headshots'} label={'Headshots'} {...this.props} />

        <div style={{ flex: 1 }} />
        <div
          onClick={() => this.setState({ toggle: !toggle })}
          style={[gstyles.p1, { color: theme.text(0.5), cursor: 'pointer' }, gstyles.bottom_1]}>
          {toggle ? 'Expand' : 'Shrink'}
        </div>
        <div
          onClick={logout}
          style={[gstyles.p1, { color: theme.text(0.5), cursor: 'pointer' }, gstyles.bottom_4]}>
          Logout
        </div>

      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
  };
}

export default withRouter(connect(mapStateToProps, { push, logout })(Radium(Sidebar)));
