import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { View } from 'view/global/components';
import TeamGridItem from "../lol_teams/TeamGridItem";
import { generateStylesSelector } from 'view/global/utils/selectors';



class TeamsGrid extends Component{
  constructor(props) {
    super(props);
    this.state = {}
  }
  sort = (key, opposite) => {
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const state = prevState;
    if (prevState.teams !== nextProps.teams) {
      state.sortedTeams = nextProps.teams;
      state.probuilds = nextProps.teams;
      state.sortedKey = 'name';
    }
    return state;
  }
  render() {
    const {sortedKey, sortedTeams} = this.state;
    const { gstyles, theme, setUndoItem } = this.props;
    return (<View>
      <View row style={gstyles.bottom_1}>
        <div style={[gstyles.caption, { color: theme.text() }]}>
          {sortedTeams.length} Teams
        </div>
        <div style={{ flex: 1 }} />
        <div
            onClick={() => this.sort('name')}
            style={[
              gstyles.caption,
              {
                color: sortedKey === 'name' ? theme.text() : theme.text(0.5),
                cursor: 'pointer',
                fontWeight: sortedKey === 'name' && 'bold',
              },
              gstyles.right_2,
            ]}>
          Name
        </div>
        <div
            onClick={() => this.sort('region')}
            style={[
              gstyles.caption,
              {
                color: sortedKey === 'region' ? theme.text() : theme.text(0.5),
                cursor: 'pointer',
                fontWeight: sortedKey === 'region' && 'bold',
              },
              gstyles.right_2,
            ]}>
          Region
        </div>
      </View>
      <View row style={{ flexWrap: 'wrap' }}>
        {sortedTeams.map((data, index) => {
          return <TeamGridItem setUndoItem={setUndoItem} key={index} item={data} />;
        })}
      </View>
    </View>);
  }
}
function generateStyles(theme) {
  return {};
}
const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    teams: state.product.lol.teams
  };
}

export default connect(mapStateToProps)(Radium(TeamsGrid))