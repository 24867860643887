export const UPDATE_CHAMPIONS = 'lol/UPDATE_CHAMPIONS';
export const UPDATE_ITEMS = 'lol/UPDATE_ITEMS';
export const UPDATE_RUNES = 'lol/UPDATE_RUNES';
export const UPDATE_SPELLS = 'lol/UPDATE_SPELLS';
export const UPDATE_CURRENT_PATCH = 'lol/UPDATE_CURRENT_PATCH';
export const UPDATE_PATCH = 'lol/UPDATE_PATCH';
export const UPDATE_CHAMPION_TIPS = 'lol/UPDATE_CHAMPION_TIPS';
export const UPDATE_PROBUILDS = 'lol/UPDATE_PROBUILDS';
export const UPDATE_PROBUILDS_EDITOR = 'lol/UPDATE_PROBUILDS_EDITOR';
export const UPDATE_TEAMS = 'lol/UPDATE_TEAMS';
export const UPDATE_TEAMS_EDITOR = 'lol/UPDATE_TEAMS_EDITOR';
export const UPDATE_TIERS = 'lol/UPDATE_TIERS';
export const UPDATE_META_REPORT = 'lol/UPDATE_META_REPORT';
export const UPDATE_TOAST = 'lol/UPDATE_TOAST';
