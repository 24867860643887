import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { deleteMap, putMap, fetchAndUpdateMaps } from 'common/reducers/product/valorant/actions';
import { connect } from 'react-redux';
import uuid from 'common/utils/uuid'

const getOtherMaps = (map, list) => list.filter((item) => item.key !== map.key)

class ValorantMaps extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAndUpdateMaps());
    if (this.props.location.state && this.props.location.state.map && this.props.ListOfMaps.length !== 0) {
      this.handleAddMap(this.props.location.state.map, this.props.location.state.id);
      this.props.history.replace({
        state: {},
      });
    }
  }

  componentDidUpdate() {
    if (this.props.location.state && this.props.location.state.mapItem && this.props.ListOfMaps.length !== 0) {
      this.handleAddMap(this.props.location.state.mapItem, this.props.location.state.id);
      this.props.history.replace({
        state: {},
      });
    }
  }

  handleAddMap(newMap, id) {
    const ListOfMaps = this.props.ListOfMaps
     if (id === undefined) {
      newMap.key = uuid();
    }
    this.props.dispatch(putMap({map:newMap, ListOfMaps, index:id}));
  }

  handleDeleteMap(target) {
    const otherMaps = getOtherMaps(target, this.props.ListOfMaps)
    this.props.dispatch(deleteMap({map:target, otherMaps}));
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid card-container">
          <div className="add-content-list-item-button">
            <Link
              to={{
                pathname: '/valorant/mapdetails',
              }}
            >
              <button className="btn btn-larg btn-primary btn-block">
                Add New Map
              </button>
            </Link>
          </div>
          <div className="row">
            {this.props.ListOfMaps.map((mapItem, index) => (
              <div className="col-md-6" key={mapItem.key}>
                <div className="card text-white">
                  <Link
                    to={{
                      pathname: '/valorant/mapdetails',
                      state: { ...mapItem, id: index },
                    }}
                  >
                    <img src={mapItem.images.hero} className="card-img" />
                  </Link>
                  <div className="row footer-row">
                    <div className="col-md-10">
                      <h4>{mapItem.name} </h4>
                    </div>
                    <div className="col-md-2 delete-map-icon">
                      <i
                        onClick={() => this.handleDeleteMap(mapItem)}
                        className="fa fa-2x fa-trash delete-icon"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ListOfMaps: state.product.valorant.maps || []
})

export default connect(mapStateToProps)(ValorantMaps);
