import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import {
  fetchAndUpdateGuides,
  deleteGuide,
} from "common/reducers/product/valorant/actions/guides";
import { connect } from "react-redux";
import { isPinned } from "common/utils/pinned";

class ValorantTips extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAndUpdateGuides());
  }
  handleDeleteTip(target) {
    const otherTips = this.props.ListOfTips.filter(
      (tip) => tip.id !== target.id
    );
    this.props.dispatch(deleteGuide({ otherTips, tip: target }));
  }

  render() {
    const { ListOfTips } = this.props;
    let lastPinned = -1;
    const tbody = ListOfTips.map((tip, index) => {
      if (isPinned(tip)) {
        lastPinned = index;
      }
      return (
        <tr key={`${tip.id}`}>
          <td>{tip.title}</td>
          <td>{tip.agent}</td>
          <td>{tip.side}</td>
          <td>{tip.map}</td>
          <td>{tip.difficulty}</td>
          <td>
            <Link
              to={{
                pathname: `/valorant/tips/${tip.id}`,
                state: { lastPinned },
              }}
            >
              <i className="fa fa-pencil edit-icon" aria-hidden="true" />
            </Link>
          </td>
          <td>
            <i
              onClick={() => this.handleDeleteTip(tip)}
              className="fa fa-trash delete-icon"
              aria-hidden="true"
            />
          </td>
          {isPinned(tip) && (
            <td>
              <i className="fa fa-map-pin" />
            </td>
          )}
        </tr>
      );
    });

    return (
      <React.Fragment>
        <div className="add-content-list-item-button">
          <Link
            to={{
              pathname: `/valorant/tips/${Math.random().toString(36).slice(2)}`,
              state: { lastPinned },
            }}
          >
            <button className="btn btn-larg btn-primary btn-block">
              Add New Tip
            </button>
          </Link>
        </div>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Title</th>
              <th>Agent</th>
              <th>Side</th>
              <th>Map</th>
              <th>Difficulty</th>
            </tr>
          </thead>
          <tbody>{tbody}</tbody>
          <tfoot />
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ListOfTips: state.product.valorant.guides,
});

export default connect(mapStateToProps)(ValorantTips);