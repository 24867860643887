import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Redirect, withRouter, Switch } from 'react-router-dom';
import { View, RouteWithSubRoutes } from 'view/global/components';
import { generateStylesSelector } from 'view/global/utils/selectors';
import Sidebar from './Sidebar';

function generateStyles(theme) {
  return {};
}
class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { gstyles, theme, styles, routes, user } = this.props;
    return (
      <View style={{ height: '100%' }}>
        <View row style={{ flex: 1 }}>
          <Sidebar />
          {user ? (
            <div style={{ flex: 1, padding: 40 }}>
              <Switch>
                {routes.map((route, i) => {
                  return <RouteWithSubRoutes key={i} {...route} />;
                })}
              </Switch>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: this.props.location },
              }}
            />
          )}
        </View>
      </View>
    );
  }
}

const stylesSelector = generateStylesSelector(generateStyles);
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    gstyles: state.settings.gstyles,
    styles: stylesSelector(state.settings.theme),
    user: state.settings.user,
  };
}

export default withRouter(connect(mapStateToProps)(Radium(Home)));
